import React from "react";
import ReactPlayer from 'react-player';
import { findDOMNode } from 'react-dom';
import { Modal, Button, Image } from 'react-bootstrap';
import $ from "jquery";
import { bindAll, isEmpty } from "underscore";
import screenfull from "screenfull";
import AppComponent from "../app_component";
import Video from '../../models/Video';
import User from '../../models/User';
import Url from '../../url';
import ViewingHistory from "../../models/ViewingHistory";
import Analytics from "../../models/Analytics";
import closeButton from "../../assets/images/icons8-cancel.svg";

export default class VideoPlayer extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            contentfulId: window.location.pathname.split('/')[2],
            videoId: window.location.pathname.split('/')[3],
            title: null,
            videoUrl: null,
            seeking: false,
            totalDuration: null,
            progress: null,
            startTime: null,
            errors: null,
            playing: false,
        };
        this.viewingHistory = new ViewingHistory();
        bindAll(this, 'handleProgress', 'handleDuration', 'handleOnStart', 'handleClose',
            'handlePause', 'handleEnded', 'handleError', 'handleSeekForward',
            'handleSeekBackward', 'checkKey', 'handleModalClose', 'handleClickFullscreen');
    }

    componentDidMount() {
        window.getMe(() => this.checkSubscriptions());
        document.getElementById("footer").style.display = "none";
        document.getElementById("navbar").style.display = "none";
        document.getElementById("content").classList.remove("my-5");
        if (document.getElementsByClassName('intercom-lightweight-app')[0]) {
            document.getElementsByClassName('intercom-lightweight-app')[0].style.visibility = 'hidden';
        }
        $(window).keydown((e) => this.checkKey(e));
        Analytics.videoStarted(this.state.contentfulId);
    }

    checkKey(e) {
        if (e.which == 32) {
            //spacebar
            this.setState({ playing: !this.state.playing });
        } else if (e.which == 70) {
            //f
            this.handleClickFullscreen();
        }
    }

    handleClickFullscreen = () => {
        screenfull.toggle(findDOMNode(this.player))
    }

    checkSubscriptions() {
        const isSusbcribed = JSON.parse(window.sessionStorage.getItem(this.state.contentfulId));
        if (isSusbcribed) {
            this.getVideoDetails();
        } else {
            const user = new User(window.loggedInUser);
            user.subscribed(this.state.contentfulId)
                .then(() => {
                    window.sessionStorage.setItem(this.state.contentfulId, JSON.stringify(true));
                    this.getVideoDetails();
                }).catch(() => {
                    window.sessionStorage.removeItem(this.state.contentfulId);
                    this.setState({ errors: { message: "NOT_SUBSCRIBED_TO_VIEW_THIS_VIDEO" } });
                });
        }
    }

    getVideoDetails() {
        const video = new Video();
        video.getVimeoVideo(this.state.videoId,
            response => this.setState({
                videoUrl: response.link,
                title: response.name,
            }),
            error => this.setState({
                errors: error
            })
        );
    }

    componentWillUnmount() {
        if (!isEmpty(this.state.progress)) {
            const data = {
                viewing_history_id: this.state.viewingHistoryId,
                seconds_watched: this.state.progress.playedSeconds,
                video_contentful_id: this.state.contentfulId,
                video_title: this.state.title,
            };
            this.viewingHistory.addOrUpdateViewingHistory(data);
        }
        const timeNow = new Date().getTime();
        const watchedDuration = (timeNow - this.state.startTime) / 1000;
        Analytics.videoWatched(this.state.contentfulId, watchedDuration);
        document.getElementById("footer").style.display = "block";
        document.getElementById("navbar").style.display = "block";
        document.getElementById("content").classList.add("my-5");
        document.getElementsByClassName('intercom-lightweight-app')[0].style.visibility = '';
    }

    handleModalClose() {
        this.setState({ errors: null });
        this.navigateTo(Url.CONTENT);
    }

    handleProgress(progress) {
        if (!this.state.seeking && this.state.viewingHistoryId) {
            this.setState({ progress });
            const data = {
                viewing_history_id: this.state.viewingHistoryId,
                seconds_watched: this.state.progress.playedSeconds,
                video_contentful_id: this.state.contentfulId,
                video_title: this.state.title,
            };
            this.viewingHistory.addOrUpdateViewingHistory(data);
        }
    }

    handleDuration(duration) {
        this.setState({ totalDuration: duration });
    }

    handleOnStart() {
        var startTime = new Date().getTime();
        const data = {
            video_contentful_id: this.state.contentfulId,
            video_title: this.state.title,
        }
        this.viewingHistory.addOrUpdateViewingHistory(data, (viewingHistoryId) => this.setState({ viewingHistoryId, startTime }));
    }

    handlePause() {
        const data = {
            viewing_history_id: this.state.viewingHistoryId,
            seconds_watched: this.state.progress.playedSeconds,
            video_contentful_id: this.state.contentfulId,
            video_title: this.state.title,
        };
        this.viewingHistory.addOrUpdateViewingHistory(data);
    }

    handleEnded() {
        if (!isEmpty(this.state.progress)) {
            const data = {
                viewing_history_id: this.state.viewingHistoryId,
                seconds_watched: this.state.progress.playedSeconds,
                video_contentful_id: this.state.contentfulId,
                video_title: this.state.title,
            }
            this.viewingHistory.addOrUpdateViewingHistory(data);
        }
        const timeNow = new Date().getTime();
        const watchedDuration = (timeNow - this.state.startTime) / 1000;
        Analytics.videoWatched(this.state.contentfulId, watchedDuration);
    }

    handleClose() {
        this.handleEnded();
        if (!this.navigateBack()) {
            this.replaceTo(`${Url.VIEW_VIDEO_DETAILS}/${this.state.contentfulId}`);
        }
    }

    handleError(error) {
        this.setState({ errors: error });
    }

    handleSeekForward() {
        if (!isEmpty(this.state.progress)) {
            if (this.state.progress.playedSeconds + 15 > this.state.duration) {
                this.player.seekTo(this.state.duration, 'seconds');
            } else {
                this.player.seekTo(this.state.progress.playedSeconds + 15, 'seconds');
            }
        } else {
            this.player.seekTo(15, 'seconds');
        }
    }

    handleSeekBackward() {
        if (!isEmpty(this.state.progress)) {
            if (this.state.progress.playedSeconds - 15 > 0) {
                this.player.seekTo(this.state.progress.playedSeconds - 15, 'seconds');
            } else {
                this.player.seekTo(0, 'seconds');
            }
        } else {
            this.player.seekTo(15, 'seconds');
        }
    }

    ref = player => {
        this.player = player
    }

    render() {
        return (
            <div>
                <Modal show={!isEmpty(this.state.errors)} onHide={this.handleModalClose} backdrop='static' centered>
                    <Modal.Body className="text-center">{this.renderError('message', "modal-message") ? this.renderError('message', "modal-message") : this.state.errors}</Modal.Body>
                    <Modal.Footer className="justify-content-center border-0">
                        <Button className="modal-btn-purple px-5" onClick={this.handleModalClose}>
                            OK
          				</Button>
                    </Modal.Footer>
                </Modal>

                {(() => {
                    if (isEmpty(this.state.errors)) {
                        return (
                            <div className='player-wrapper'>
                                <Image src={closeButton} className="close-button cursor-pointer" roundedCircle onClick={this.handleClose}/>

                                <ReactPlayer
                                    id="react-player1"
                                    className="react-player"
                                    ref={this.ref}
                                    url={this.state.videoUrl}
                                    playing={this.state.playing}
                                    controls
                                    progressInterval={5000}
                                    onError={this.handleError}
                                    onProgress={this.handleProgress}
                                    onDuration={this.handleDuration}
                                    onStart={this.handleOnStart}
                                    onPause={this.handlePause}
                                    onEnded={this.handleEnded}
                                    width='100%'
                                    height='100%'
                                    playsinline={false}
                                    config={{
                                        vimeo: {
                                            playerOptions: {
                                                width: window.innerWidth,
                                                height: window.innerHeight,
                                                maxwidth: window.innerWidth,
                                            }
                                        }
                                    }}
                                />
                            </div>
                        )
                    }
                })()}
            </div >
        );
    }
}

