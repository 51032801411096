export default class BaseModel {
    constructor(attributes) {
        this.model = attributes || {};
    }

    set(key, value) {
        this.model[key] = value;
    }

    get(key) {
        return this.model[key];
    }
}