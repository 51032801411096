import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import '../../assets/sass/components/stripe.scss';
import { Button, Modal } from 'react-bootstrap';
import StripeSubscription from '../../models/StripeSubscription';
import Analytics from '../../models/Analytics';

class CardDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
			markDefault: false,
		};
	}

	CARD_ELEMENT_OPTIONS = {
		style: {
			base: {
				color: '#32325d',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#a755a',
				iconColor: '#fa755a',
			},
		},
	};

	handleChange = (event) => {
		if (event.error) {
			this.setState({ error: event.error.message });
		} else {
			this.setState({ error: null });
		}
	};

	showSpinner = () => {
		this.refs.add_card_button.setAttribute('disabled', true);
		this.refs.add_card_spinner.setAttribute(
			'class',
			'spinner-border spinner-border-sm'
		);
	};

	hideSpinner = () => {
		this.refs.add_card_spinner.removeAttribute('class');
		this.refs.add_card_button.removeAttribute('disabled');
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.showSpinner();
		const card = await this.props.elements.getElement(CardElement);
		this.props.stripe
			.createToken(card, {
				name: this.props.holderEmail,
			})
			.then((result) => {
				if (result.error) {
					this.setState({ error: result.error.message });
					this.hideSpinner();
				}
				if (result.token) {
					this.setState({ error: null });
					let stripeSubscription = new StripeSubscription();

					stripeSubscription.addNewCard({ userId: window.loggedInUser.id, cardToken: result.token },)
						.then(response => {
							this.handleClose(response.data.last_four);
							Analytics.captureEvent('changeCreditCard');
							this.hideSpinner();
						}).catch(error => {
							if (error.response.data.error) {
								this.setState({ error: error.response.data.error });
								this.hideSpinner();
								return;
							}
							this.setState({ error: 'Error in updating card. Please try again' });
							this.hideSpinner();
						}
					);
				}
			});
	};

	handleClose = (lastFour) => {
		this.setState({
			error: '',
		});
		this.props.handleClose(lastFour);
	};

	render() {
		return (
			<Modal show={this.props.show} onHide={this.handleClose} className="backdrop-image">
				<Modal.Header closeButton>
					<div className="update-card-heading">
						<b>Change Credit Card</b>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div className="card-errors text-center" role="alert">
							{this.state.error}
						</div>
						<form onSubmit={this.handleSubmit}>
							<div className="form-row flex-column">
								<CardElement
									id="card-element"
									options={this.CARD_ELEMENT_OPTIONS}
									onChange={this.handleChange}
								/>
							</div>
						</form>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						ref="add_card_button"
						onClick={this.handleSubmit}
						type="submit"
					>
						<span ref="add_card_spinner" role="status" aria-hidden="true" />{' '}
						Update Card
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
export default CardDetails;
