import React from 'react';
import FilterDropDown from './filter_drop_down';
import { Row } from 'react-bootstrap';
import _ from 'underscore';
class Filters extends React.Component {
	constructor(props) {
		super(props);
		_.bindAll(this, 'onCheckboxClick', 'clearFilter');
	}

	onCheckboxClick(category, value) {
		this.props.onCheckboxClick(category, value);
	}
	clearFilter() {
		this.props.onClearFilter();
	}

	render() {
		return (<React.Fragment>
			{this.props.isFilterApplied ? 
			<Row className="justify-content-end px-4 pb-3">
				<span className="clear-filter" onClick={this.clearFilter}>Clear</span>
			</Row>
			: null}
			<Row className="dropdown-row">
				<FilterDropDown
					key="durations"
					category="durations"
					title="Video duration"
					options={this.props.videoAttributes.durations}
					onCheckboxClick={this.onCheckboxClick}
					selectedOptions={this.props.selectedFilters.selectedDurations}
				/>
				<FilterDropDown
					key="yogaStyles"
					title="Type of Practice"
					category="yogaStyles"
					options={this.props.videoAttributes.yogaStyles}
					onCheckboxClick={this.onCheckboxClick}
					selectedOptions={this.props.selectedFilters.selectedYogaStyles}
				/>
				<FilterDropDown
					key="focus"
					category="focusAreas"
					title="Focus"
					options={this.props.videoAttributes.focusAreas}
					onCheckboxClick={this.onCheckboxClick}
					selectedOptions={this.props.selectedFilters.selectedFocusAreas}
				/>
				<FilterDropDown
					key="bodyParts"
					category="bodyParts"
					title="Body part"
					options={this.props.videoAttributes.bodyParts}
					onCheckboxClick={this.onCheckboxClick}
					selectedOptions={this.props.selectedFilters.selectedBodyParts}
				/>
				<FilterDropDown
					key="instructors"
					category="instructors"
					title="Instructor"
					options={this.props.videoAttributes.instructors}
					onCheckboxClick={this.onCheckboxClick}
					selectedOptions={this.props.selectedFilters.selectedInstructors}
				/>
			</Row>
			</React.Fragment>
		);
	}
}

export default Filters;
