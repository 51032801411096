import BaseModel from "./BaseModel";
import axios from "../axios";
import { getApiUrl } from "../utils/apiUrls";

export default class InstructorModel extends BaseModel {
    getInstructorDeatils(instructor_id, callback, failCallback) {
        axios.get(getApiUrl('GET_INSTRUCTOR_DETAIL', { instructor_contentful_id: instructor_id }))
            .then((response) => {
                callback(response.data);

            })
            .catch((error) => {
                failCallback(error);
            });
    }
}