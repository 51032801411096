import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import _, {isEmpty} from "underscore";
import yoga_icon from "../../assets/images/yoga_icon.svg"
import ViewingHistory from "../../models/ViewingHistory";

export default class VideoStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            video_stats: ViewingHistory.getVideoStatsFromHistory(this.props.viewing_history),
            active_stat: 'week',
            week_stat_class: 'journey-btn-active',
            all_time_stat_class: 'journey-btn-inactive',
        };
        _.bindAll(this, 'loadStats')
    }

    loadStats(stats) {
        if (stats === 'week' && this.state.active_stat !== 'week') {
            this.setState(
                {
                    active_stat: 'week',
                    week_stat_class: 'journey-btn-active',
                    all_time_stat_class: 'journey-btn-inactive'
                }
            );
        }
        if (stats === 'all_time') {
            this.setState(
                {
                    active_stat: 'all_time',
                    week_stat_class: 'journey-btn-inactive',
                    all_time_stat_class: 'journey-btn-active'
                }
            );
        }
    }

    renderStats () {
        let active_stat = this.state.active_stat;
        let videos = this.state.video_stats[active_stat].sessions;
        let seconds_practiced = this.state.video_stats[active_stat].seconds_practiced;

        let practiceDuration = ViewingHistory.getPracticeDuration(seconds_practiced)

        return (
            <Row className='d-flex align-items-center text-center justify-content-center'>
                <Col md={12} xs={12} className='pt-4 pb-4 pb-md-3'>
                    <p className='stats heading-1'>
                        {
                            (() => {
                                if (practiceDuration.hours > 0) {
                                    return (
                                        <React.Fragment>
                                            {practiceDuration.hours}<span className="heading-2">hrs</span>{' '}
                                        </React.Fragment>
                                    );
                                }
                            })()
                        }
                        {practiceDuration.minutes}<span className="heading-2">mins</span>
                    </p>
                </Col>
                <Col md={8} xs={8} className='h-divider'/>
                <Col md={12} xs={12} className='pt-4 pb-4 pb-md-3 pt-md-3'>
                    <p className='stats heading-1'>{videos} <span className="heading-2">videos</span></p>
                </Col>
            </Row>
        );
    }

    render() {
        if (isEmpty(this.state.video_stats) || !this.state.video_stats.all_time.sessions) {
            return (<div />);
        }

        return (
            <Row className='mr-auto ml-auto mt-md-5'>
                <Col className='order-md-first order-3 offset-md-1' md='1' xs='12'>
                    <Row>
                        <Col
                            md={12}
                            xs={6}
                            className={`journey-btn journey-btn-week ${this.state.week_stat_class} cursor-pointer pr-0 pl-0`}
                            onClick={() => this.loadStats('week')}
                        >
                            <p className='mb-0 pt-2 pb-2 heading-6'>This week</p>
                        </Col>
                        <Col
                            className={`journey-btn journey-btn-all-time ${this.state.all_time_stat_class} cursor-pointer`}
                            onClick={() => this.loadStats('all_time')}
                        >
                            <p className='mb-0 pt-2 pb-2 heading-6'>All time</p>
                        </Col>
                    </Row>
                </Col>
                <Col className='yoga-col order-2 order-md-1 align-items-center d-flex justify-content-center pl-md-0 pr-md-0' md={2} xs={12}>
                    <Row>
                        <Col xs={12} md={12} className='yoga-icon mt-md-n1 mb-md-0 mb-4 w-100 align-items-center d-flex justify-content-center'>
                            <img src={yoga_icon} alt="yoga"/>
                        </Col>
                    </Row>
                </Col>
                <Col className='video-stats order-3 ml-md-n2 mt-md-n1 pr-0' md={3} xs={12}>
                    {this.renderStats()}
                </Col>
                <Col md={4} xs={12} className='reflect d-md-flex align-md-items-center text-center order-md-last order-first mt-md-n1 justify-content-center'>
                    <p>Reflect</p>
                </Col>
            </Row>
        );
    }
}