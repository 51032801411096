import * as React from "react";
import { isEmpty } from "underscore";
import { Row, Col } from "react-bootstrap";
import User from "../../models/User";
import DateUtil from "../../utils/dateUtil";

export default class IAPDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: new User(),
            showModal: false,
            error: null,
        };
    }

    showStatus() {
        let subscription_status = this.props.subscription.iap.is_active ? 'Active' : 'Canceled';

        return (
            <Col sm={7} xs={7}>
                <p className={"mb-0 mb-md-1 "  + (subscription_status === 'Active' ? 'dot' : 'red-dot')} />
                <span className={'pl-2 heading-4 ' + (subscription_status === 'Active' ? 'active-profile green-color' : 'red-color')}>
                    {subscription_status}
                </span>
            </Col>
        );
    }

    render() {
        let subscription = this.props.subscription;
        if (subscription && isEmpty(subscription.iap)) {
            return <div />;
        }
        let classNameForText = subscription.iap.is_active ? "details" : "inactive-subscription-text";
        let classNameForLink = subscription.iap.is_active ? "link" : "inactive-subscription-text";
        return (
            <React.Fragment>
                <Row className="mb-3 mt-0 mt-md-5">
                    {this.showStatus()}
                </Row>
                <Row className={"mt-2 "+classNameForText}>
                    <Col lg={8} sm={12} xs={12}>
                        <p className="heading-5 ">Subscribed to
                        <span className="font-weight-bold heading-5"> {subscription.iap.subscribed_to} </span>
                        </p>
                    </Col>
                </Row>
                <Row className={classNameForText}>
                    <Col lg={8} sm={12} xs={12} className='pr-0'>
                        <p className="pr-0 heading-5">Subscribed on
                        <span className="font-weight-bold heading-5"> {DateUtil.epochToLocalDate(subscription.iap.subscribed_on)}</span>
                        </p>
                    </Col>
                </Row>
                <Row className={classNameForText}>
                    <Col sm={8}>
                        <p className="heading-5">Your subscription using Apple subscriptions. To manage your subscription, see</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={10}>
                        <a href="https://support.apple.com/en-us/HT202039" className={classNameForLink} target="_blank" rel="noopener noreferrer">
                            https://support.apple.com/en-us/HT202039
                    </a>
                    </Col>
                </Row>
            </React.Fragment>);
    }
}