const VIMEO_CLIENT_ID = process.env.REACT_APP_VIMEO_CLIENT_ID;

export const API_URLS = (key, params) => {
    const routes = {
        GET_SUBSCRIPTIONS: `/api/users/${params.id}/list_subscriptions`,
        ADD_CARD: `/api/users/${params.id}/stripe_subscriptions/add_card`,
        CANCEL_SUBSCRIPTION: `/api/users/${params.id}/stripe_subscriptions/cancel_subscription`,
        GET_ME: `/api/user/me`,
        LOGIN: `/api/login`,
        REFRESH_TOKEN: '/api/refresh-login-token',
        VIEW_VIDEO_DETAILS: `/api/users/${params.userId}/videos/${params.contentfulVideoId}`,
        INSTRUCTOR_VIDEOS: `/api/users/${params.userId}/instructors`,
        RESET_PASSWORD: `/api/reset-password`,
        VIDEO_STATS: '/api/user/video-stats',
        VIEWING_HISTORY: '/api/user/viewing-history',
        ADD_OR_UPDATE_VIEWING_HISTORY: `/users/${params.id}/update_viewing_history`,
        GET_VIMEO_VIDEO: `https://api.vimeo.com/videos/${params.vimeoId}?client_id=${VIMEO_CLIENT_ID}`,
        UPDATE_PASSWORD: '/api/user/update-password',
        VALIDATE_PASSWORD: '/api/user/validate-password',
        GET_INSTRUCTOR_DETAIL: `/api/instructor/${params.instructor_contentful_id}`,
        SUBSCRIBED: `/api/user/subscribed/${params.contentful_video_id}`
	};
	return routes[key];
};

export function getApiUrl(key, { ...params } = {}) {
    return API_URLS(key, params);
}
