import React from 'react';
import { Container, Row, Col, Card, Button, Form, Image } from 'react-bootstrap';
import _ from 'underscore';
import Url from "../../url";
import AppComponent from "../app_component";
import User from '../../models/User';
import zenevate_logo from "../../assets/images/zenevate_logo.png";
import Analytics from '../../models/Analytics';

export default class ResetPassword extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            errors: null,
            successMessage: null,
        };
        _.bindAll(this, 'sendResetRequest', 'changeState', 'handleClose', 'onSuccess');
    }

    changeState(event) {
        let fieldValue = event.target.value;
        let fieldName = event.target.name;
        this.setState({ [fieldName]: fieldValue });
    }

    handleClose() {
        this.navigateTo(Url.LOGIN);
    };

    onFail(error) {
        if (error.response) {
            if(error.response.data.error['message'] === "EMAIL_NOT_FOUND"){
                this.setState({
                    errors: null,
                    successMessage: "If you have an account with this email address, you will receive an email within the next 5 minutes to reset your password."
                })
            }
            else {
                this.setState({ errors: error.response.data.error, successMessage: null });
            }
        }
    }

    onSuccess(response) {
        if (response.status === 200) {
            this.setState({
                errors: null,
                successMessage: "If you have an account with this email address, you will receive an email within the next 5 minutes to reset your password."
            })
        }
    }

    sendResetRequest() {
        const user = new User();
        user.resetPassword({ email: this.state.email })
            .then(response => {
                this.onSuccess(response);
                Analytics.captureEvent('resetPassword');
            })
            .catch(error => {
                this.onFail(error);
            });
    }

    renderSuccess() {
        if (!this.state.successMessage) {
            return <span />;
        }
        return <span className='text-success'>{this.state.successMessage}</span>;
    }

    render() {
        return (
            <Container fluid className="reset-password-container my-n5">
                <Row className="container-background-color">
                    <Col md={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }} className='text-center'>
                        <Image src={zenevate_logo} />
                        <Card className="text-center p-3 card-box-shadow mt-3">
                            <Card.Body>
                                <div className="pb-3 title">
                                    Reset Password
                                </div>
                                <div className="pb-3 sub-title">
                                    Enter the email you've registered with. We'll send you the instructions there.
                                </div>
                                {this.renderError('message')}
                                {this.renderSuccess()}
                                <Form className='pt-4'>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control type="email" name="email" placeholder="Registered Email" onChange={this.changeState} className="border-bottom-1" />
                                    </Form.Group>
                                </Form>
                                <Button
                                    variant="primary"
                                    type="button"
                                    block
                                    disabled={!this.state.email}
                                    onClick={this.sendResetRequest}
                                    className="my-4 request-reset-button cursor-pointer"
                                >
                                    REQUEST PASSWORD RESET
                                </Button>
                                <a onClick={this.handleClose} className="back-login cursor-pointer">
                                    Back to Login
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}