import React from 'react';
import AppComponent from '../app_component';
import { Row, Col, Button, Image } from 'react-bootstrap';
import dangerIcon from '../../assets/images/danger.svg';
import _ from 'underscore';
import Analytics from '../../models/Analytics';

export default class ErrorBanner extends AppComponent {
	constructor(props) {
		super(props);
		this.state = {
			visible: props.visible,
		};
		_.bindAll(this, 'navigateToZenevatePricing');
	}

	navigateToZenevatePricing(){
		let username = window.loggedInUser.email
		let fname = window.loggedInUser.firstName
		let lname = window.loggedInUser.lastName
		window.clearStorage();
		window.loggedInUser = null;
		Analytics.onLogout();
		var subscriptionUrl = "https://zenevate.com/staging/pricing";
        if (process.env.REACT_APP_ENV === 'production') {
            subscriptionUrl = "https://zenevate.com/pricing"
        }
		window.location.href = `${subscriptionUrl}?email=${username}&fname=${fname}&lname=${lname}`
	}

	render() {
		let visibility = this.props.visible ? '' : 'd-none';
		const hasNoSubscribedHistoryMsg = "You have not completed the process to subscribe to Zenevate. Please subscribe in order to access Zenevate content.";
		const hasNoSubscribedActiveMsg = "You do not have any active subscriptions.";
		let content = this.props.hasNoSubscribedHistory ? hasNoSubscribedHistoryMsg : hasNoSubscribedActiveMsg;
		return (
			<Col
				md={{ span: 12 }}
				lg={{ span: 10 }}
				xl={{ span: 9 }}
				className={'no-active-subscription p-3 ' + visibility}
			>
				<Row style={{ alignItems: 'center' }}>
					<Col sm={12} md={1} className="d-flex justify-content-center">
						<Image className="danger-icon" src={dangerIcon} alt="dangerIcon" />
					</Col>
					<Col sm={12} md={7} xl={8} className="content-div my-2">
						<span className="content heading-4">
							{content}
						</span>
					</Col>
					<Col sm={12} md={4} xl={3} className="d-flex justify-content-center">
						<Button onClick={this.navigateToZenevatePricing} className="subscribe-button">Subscribe Now</Button>
					</Col>
				</Row>
			</Col>
		);
	}
}
