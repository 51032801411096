import React from "react";
import Page from "../page";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import User from "../../models/User";
import { isEmpty } from "underscore";
import StripePaymentDetails from "./stripe_payment_details";
import ProfileDetails from "./profile_details";
import IAPDetails from "./iap_details";
import _ from 'underscore';
import Url from "../../url";
import ErrorBanner from '../common/ErrorBanner';

export default class AccountPage extends Page {

    constructor(props) {
        super(props);
        this.state = {
            subscription: {},
            user: new User(window.loggedInUser)
        };
        _.bindAll(this, 'fetchSubscription', 'onCancelSubscription', 'onUpdatePassword')
    }

    componentDidMount() {
        if (!window.loggedInUser) {
            window.getMe(() => this.fetchSubscription());
            return;
        }
        this.fetchSubscription()
    }

    fetchSubscription() {
        this.state.user.getSubscriptions((response) => {
            this.setState({ subscription: response });
        });
    }

    renderSubscriptionError() {
        if (this.state.user.hasNoSubscribedHistory(this.state.subscription)) {
            return (
                <div className="d-flex justify-content-center mb-2 align-items-center">
                    <ErrorBanner hasNoSubscribedHistory={true} visible={true} />
                </div>
            );
        }

        if (!this.state.user.isSubscriptionActive(this.state.subscription)) {
            return (
                <div className="d-flex justify-content-center mb-2 align-items-center">
                    <ErrorBanner hasNoSubscriptionActive={false} visible={true} />
                </div>
            );
        }

        return (<div />);
    }

    onCancelSubscription(cancel_on) {
        let subscription = this.state.subscription;

        if (cancel_on) {
            subscription.stripe.cancel_on = cancel_on;
        }
        this.setState({ subscription });
    }

    onUpdatePassword() {
        this.navigateTo(Url.UPDATE_PASSWORD);
    }

    render() {

        if (isEmpty(this.state.subscription)) {
            return (<div />);
        }
        return (
            <Col md={{ span: 10, offset: 1 }} className="account-page">
                {this.renderSubscriptionError()}
                <Row>
                    <p className="account_details col-lg-3 col-sm-4 col-5 pr-0 heading-1 mb-0"> My Account</p>
                    {(() => {
                        if (!isEmpty(this.state.subscription.iap) || !isEmpty(this.state.subscription.stripe)) {
                            if (this.state.user.isSubscriptionActive(this.state.subscription)) {
                                return (
                                    <Col sm={7} xs={7} className="align-self-center">
                                        <p className="dot mb-0 mb-md-1" />
                                        <span className="pl-2 active-profile heading-4 green-color"> Active
                                      </span>
                                    </Col>
                                );
                            }
                            return (
                                <Col sm={7} xs={7}>
                                    <p className="red-dot mb-0 mb-md-1" />
                                    <span className="pl-2 heading-4 red-color"> Canceled</span>
                                </Col>
                            );
                        }
                        return (
                            <Col sm={7} xs={7}>
                                <p className="red-dot mb-0 mb-md-1" />
                                <span className="pl-2 heading-4 red-color"> Inactive</span>
                            </Col>
                        );
                    })()}
                </Row>
                <hr />
                <ProfileDetails onUpdatePassword={this.onUpdatePassword} />
                {(() => {
                    if (!isEmpty(this.state.subscription.iap) || !isEmpty(this.state.subscription.stripe)) {
                        return (
                            <div>
                                <hr />
                                <Row>
                                    <Form.Label column sm={4} lg={3} className="details-heading heading-3 pt-0">
                                        Subscription Details
                                </Form.Label>
                                    <Col sm={8} lg={9}>
                                        {(() => {
                                            if (this.state.subscription.iap) {
                                                return (
                                                    <IAPDetails subscription={this.state.subscription} />
                                                );
                                            }
                                        })()}
                                        {(() => {
                                            if (this.state.subscription.stripe) {
                                                return (
                                                    <StripePaymentDetails subscription={this.state.subscription} onCancelSubscription={this.onCancelSubscription}/>
                                                );
                                            }
                                        })()}
                                    </Col>
                                </Row>
                            </div>
                        );
                    }
                })()}
            </Col>
        );
    }

}