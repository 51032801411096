import BaseModel from "./BaseModel";
import axios from "../axios";
import { getApiUrl } from "../utils/apiUrls";
import DateUtil from "../utils/dateUtil";
import Url from "../url";
import Analytics from "./Analytics";
import { createBrowserHistory } from 'history';

export default class ViewingHistory extends BaseModel {

    async getVideoStats() {
        return await axios.get(getApiUrl("VIDEO_STATS"));
    }

    async getViewingHistory() {
        return await axios.get(getApiUrl("VIEWING_HISTORY"));
    }

    static getPracticeDuration = (seconds) => {

        let hours = Math.floor(seconds / 60 / 60);
        let minutes = Math.floor(seconds / 60) - (hours * 60);

        return { hours, minutes }
    }

    static getVideoStatsFromHistory = (viewing_history) => {
        let seconds_practiced_ever = 0;
        let seconds_practiced_week = 0;
        let sessions_week = 0;

        viewing_history.forEach((video, index) => {
            seconds_practiced_ever = seconds_practiced_ever + video.seconds_watched;

            if (DateUtil.isThisWeek(video.watch_date_time)) {
                sessions_week = sessions_week + 1;
                seconds_practiced_week = seconds_practiced_week + video.seconds_watched;
            }
        })

        return {
            week: {
                sessions: sessions_week,
                seconds_practiced: seconds_practiced_week
            },
            all_time: {
                sessions: viewing_history.length,
                seconds_practiced: seconds_practiced_ever
            }
        }
    }

    addOrUpdateViewingHistory(param, callback = () => { }, failCallback = () => { }) {
        const user = window.loggedInUser;
        if (!user) {
            window.clearStorage();
            const history = createBrowserHistory();
            history.push(Url.LOGIN);
            window.location.reload(true);
        }
        const data = {
            video_contentful_id: param['video_contentful_id'],
            video_name: param['video_title'],
            video_api_id: param['viewing_history_id'] ? param['viewing_history_id'] : null,
            seconds_watched: param['viewing_history_id'] ? param['seconds_watched'] : 0,
        };
        axios.post(getApiUrl("ADD_OR_UPDATE_VIEWING_HISTORY", user.id), data)
            .then((response) => {
                callback(response.data.api_id);
            })
            .catch(error => {
                failCallback(error);
            });
    }
}
