import React from 'react';
import { Dropdown, Row } from 'react-bootstrap';
import FilterDropDown from './filter_drop_down';
import _ from 'underscore';

class MobileFilters extends React.Component {
	constructor(props) {
		super(props);
		_.bindAll(this, 'onFiltersButtonClick', 'onCheckboxClick', 'clearFilter', 'handleClickOutside');
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
	}

	handleClickOutside(event) {
		if (this.refs.dropdown_ref
			&& !this.refs.dropdown_ref.contains(event.target)
			&& this.refs.mobile_dropdown.classList.contains("show")
			&& !this.refs.mobile_dropdown.contains(event.target)) {
			this.onFiltersButtonClick();
		}
	}

	onFiltersButtonClick() {
		const currentClasses = this.refs.mobile_dropdown.getAttribute('class');
		if (!currentClasses.includes('show')) {
			this.refs.mobile_dropdown.setAttribute('class', currentClasses + ' show');
		} else {
			this.refs.mobile_dropdown.setAttribute('class', 'dropdown-menu');
		}
	}

	onCheckboxClick(category, value) {
		this.props.onCheckboxClick(category, value);
	}

	clearFilter() {
		this.props.onClearFilter();
	}

	render() {
		return (
			<React.Fragment>
				{this.props.isFilterApplied ?
					<Row className="justify-content-end px-4 pb-2">
						<span className="clear-filter" onClick={this.clearFilter}>Clear</span>
					</Row>
					: null}
				<div className="mobile-filters">
					<Dropdown.Toggle
						variant="light"
						bsPrefix=" filterDropDown dropdown-toggle"
						onClick={this.onFiltersButtonClick}
						className="heading-5"
						ref="dropdown_ref"
					>
						Filter and sort
				</Dropdown.Toggle>
					<ul
						className="dropdown-menu"
						ref="mobile_dropdown"
						role="menu"
						aria-labelledby="dropdownMenu"
					>
						<FilterDropDown
							key="durations"
							category="durations"
							title="Video duration"
							subMenu={true}
							options={this.props.videoAttributes.durations}
							onCheckboxClick={this.onCheckboxClick}
							selectedOptions={this.props.selectedFilters.selectedDurations}
						/>
						<FilterDropDown
							key="yogaStyles"
							title="Type of Practice"
							category="yogaStyles"
							subMenu={true}
							options={this.props.videoAttributes.yogaStyles}
							onCheckboxClick={this.onCheckboxClick}
							selectedOptions={this.props.selectedFilters.selectedYogaStyles}
						/>
						<FilterDropDown
							key="focus"
							category="focusAreas"
							title="Focus"
							options={this.props.videoAttributes.focusAreas}
							subMenu={true}
							onCheckboxClick={this.onCheckboxClick}
							selectedOptions={this.props.selectedFilters.selectedFocusAreas}
						/>
						<FilterDropDown
							key="bodyParts"
							category="bodyParts"
							title="Body part"
							options={this.props.videoAttributes.bodyParts}
							subMenu={true}
							onCheckboxClick={this.onCheckboxClick}
							selectedOptions={this.props.selectedFilters.selectedBodyParts}
						/>
						<FilterDropDown
							key="instructors"
							category="instructors"
							title="Instructor"
							subMenu={true}
							options={this.props.videoAttributes.instructors}
							onCheckboxClick={this.onCheckboxClick}
							selectedOptions={this.props.selectedFilters.selectedInstructors}
						/>
					</ul>
				</div>
			</React.Fragment>
		);
	}
}
export default MobileFilters;
