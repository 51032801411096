import BaseModel from "./BaseModel";
import { getApiUrl } from "../utils/apiUrls";
import axios from '../axios/index'
import _, { isEmpty } from "underscore";
import StripeSubscription from "./StripeSubscription";

export default class User extends BaseModel {

    async getMe() {
        return await axios.get(getApiUrl("GET_ME"));
    }

    async login(data) {
        return await axios.post(getApiUrl("LOGIN"), data);
    }

    async refreshToken(data) {
        return await axios.post(getApiUrl("REFRESH_TOKEN"), data);
    }

    async resetPassword(data) {
        return await axios.post(getApiUrl("RESET_PASSWORD"), data);
    }

    async updatePassword(data) {
        return await axios.post(getApiUrl("UPDATE_PASSWORD"), data);
    }

    async validatePassword(data) {
        return await axios.post(getApiUrl("VALIDATE_PASSWORD"), data);
    }

    async subscribed(contentful_video_id) {
        return await axios.get(getApiUrl("SUBSCRIBED", { contentful_video_id: contentful_video_id }));
    }

    getSubscriptions(callback) {
        axios.get(getApiUrl("GET_SUBSCRIPTIONS", { id: window.loggedInUser.id }))
            .then(response => { 
                window.sessionStorage.setItem('subscription_details', JSON.stringify(response.data));
                callback(response.data) 
            });
    }

    getSubscriptionError(subscriptions) {

        let stripe = subscriptions.stripe;
        let iap = subscriptions.iap;

        if (isEmpty(subscriptions) || (isEmpty(stripe) && isEmpty(iap))) {
            return "You do not have any active subscriptions.";
        }

        let isStripeActive = !isEmpty(stripe) && _.contains(StripeSubscription.activeStatus, stripe.status);
        let isIAPActive = !isEmpty(iap) && iap.status === 'active';

        if (isStripeActive && isIAPActive) {
            return "You have multiple subscriptions";
        }

        if (isStripeActive || isIAPActive) {
            return '';
        }
        return "You do not have any active subscriptions.";
    }

    isSubscriptionActive(subscriptions) {

        let stripe = subscriptions.stripe;
        let iap = subscriptions.iap;

        if (isEmpty(subscriptions) || (isEmpty(stripe) && isEmpty(iap))) {
            return false;
        }

        let isStripeActive = !isEmpty(stripe) && _.contains(StripeSubscription.activeStatus, stripe.status);
        let isIAPActive = !isEmpty(iap) && iap.is_active === true;

        return isStripeActive || isIAPActive;
    }

    hasNoSubscribedHistory(subscriptions){
        let stripe = subscriptions.stripe
        let iap = subscriptions.iap
        if (isEmpty(subscriptions) || (isEmpty(stripe) && isEmpty(iap))) {
            return true;
        }
        return false;
    }

    currentSubscriptionStatus(subscription) {
        if (this.isSubscriptionActive(subscription)) {
            if (subscription.stripe.cancel_on) {
                return  'Active set to cancel';
            }
            return 'Active';
        }

        if (isEmpty(subscription.stripe) && isEmpty(subscription.iap)) {
            return 'Never Subscribed';
        }

        return 'Canceled';
    }

    getOverallSubscriptionStatus(subscriptionDetails){
        let stripe = subscriptionDetails.stripe
        let iap = subscriptionDetails.iap
        
        if(isEmpty(stripe) && isEmpty(iap)){
            return "Never Subscribed";
        }
        if((stripe && !StripeSubscription.activeStatus.includes(stripe.status)) || (iap && (iap.is_active === false)) ){
            return "Inactive";
        }
        return "Active";
    }
}