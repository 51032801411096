import React from "react";
import {groupBy} from "underscore";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Image} from "react-bootstrap";
import lotusImage from "../../assets/images/lotus.svg";
import DateUtil from "../../utils/dateUtil";
import Url from "../../url";

export default class VideoHistory extends React.Component {

    constructor(props) {
        super(props);
    }

    isVideoDurationLessThan10Mins = (duration) => {
		return duration.split(" ")[0].length === 1;
	}

	formatDuration = (duration) => {
		return this.isVideoDurationLessThan10Mins(duration) ? `0${duration}` : duration;
    }
    
    renderVideoCard(video_details) {
        return video_details.map((details, index) => {
            return(
                <Col md={12} className='pb-4'>
                    <div id={details.contentful_video_id} className="card offset-lg-1 v-card  cursor-pointer" onClick={() => this.props.onClick(details.contentful_video_id)}>
                        <Row className="align-items-center mr-md-0 no-gutters">
                            <Col lg={5} md={12} xs={12}>
                                <img src={details.preview_image} className="w-100 card-video" />
                            </Col>
                            <Col lg={5} md={8} xs={8} className="pl-md-0">
                                <div className="card-body pt-3">
                                    <h4 className="card-title heading-6">{details.instructor_name}</h4>
                                    <p className="card-text card-video-title heading-5">{details.video_title}</p>
                                    <p className="card-yoga-type heading-6">
                                        {details.category[0]}
                                    </p>
                                </div>
                            </Col>
                            <Col xs={{ span: 4 }} md={{ span: 2 }} className="video-content-right py-2">
                                <Row className="journey-card-video-level">
                                    <Col xs={{ span: 12 }}>
                                        <Image
                                            className="card-video-level-image pr-1 mb-2"
                                            alt="lotus"
                                            src={lotusImage}
                                        />
                                        <span className="card-video-level-text heading-6">{details.level}</span>
                                    </Col>
                                </Row>
                                <Row className="journey-card-video-duration">
                                    <Col xs={{ span: 12 }} className="heading-5">{this.formatDuration(details.duration)}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            );
        })
    }

    renderVideoHistory() {
        let video_history = this.props.viewing_history;
        let grouped_video_history = groupBy(video_history, 'watch_date');

        return Object.keys(grouped_video_history).map((watch_date, index) => {
            return (
                <div>
                    <Row>
                        <Col md={{span: 8, offset: 2}} lg={{span: 11, offset: 1}} className="pl-md-0 pl-lg-3 offset-sm-1">
                            <p className='video-date pt-3 heading-3'>{DateUtil.toLocalDate(watch_date)}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} className='dotted'/>
                        <Col className='journey_video pb-4 pt-4' md={{span: 8, offset: 2}} lg={{span: 9, offset: 0}}>
                            <Row>
                                {this.renderVideoCard(grouped_video_history[watch_date])}
                            </Row>
                        </Col>
                    </Row>
                </div>
            );
        })
    }

    render() {
        return (
            <Row className='mr-auto ml-auto mt-5'>
                <Col className='video_history' md={{span: 10, offset: 1}} lg={{span: 10, offset: 1}}>
                    {this.renderVideoHistory()}
                </Col>
            </Row>
        );
    }
}