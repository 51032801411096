export default class Url {
    static get LOGIN() {
        return '/login';
    }

    static get ACCOUNT() {
        return '/account';
    }

    static get PRACTICE() {
        return '/practice';
    }

    static get HOME_PAGE() {
        return '/';
    }

    static get CONTENT(){
        return '/content';
    }

    static get VIEW_VIDEO_DETAILS() {
        return '/view-video-details';
    }

    static get RESET_PASSWORD() {
        return '/reset-password';
    }

    static get MY_JOURNEY(){
        return '/journey';
    }

    static get VIDEO_PLAYER() {
        return '/watch-video';
    }
    
    static get UPDATE_PASSWORD(){
        return '/update-password';
    }

    static get INSTRUCTOR_DETAIL() {
        return '/instructor';
    }
}