export default class DateUtil {
    static dateFormatOption = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    static toLocalDate(datetime) {
        return new Date(datetime).toLocaleDateString("en-US", this.dateFormatOption);
    }

    static epochToLocalDate(epoch) {
        let date = new Date(0);
        date.setUTCSeconds(epoch);
        return date.toLocaleDateString("en-US", this.dateFormatOption);
    }

    static isThisWeek (date) {
        const now = new Date();
        const weekDay = (now.getDay() + 6) % 7;
        const monthDay = now.getDate();
        const mondayThisWeek = monthDay - weekDay;

        const startOfThisWeek = new Date(+now);
        startOfThisWeek.setDate(mondayThisWeek);
        startOfThisWeek.setHours(0, 0, 0, 0);

        const startOfNextWeek = new Date(+startOfThisWeek);
        startOfNextWeek.setDate(mondayThisWeek + 7);

        const dateToCompare = new Date(date);

        return dateToCompare >= startOfThisWeek && dateToCompare < startOfNextWeek;
    }

	static isCurrentMonth(date) {
		let originalDate = new Date(date);
		let offset = originalDate.getTimezoneOffset();
		let dateToCompare = originalDate.getTime() + (offset * 60 * 1000)

		let today = new Date();
		let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).getTime();
		let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getTime();

		return dateToCompare >= firstDayOfMonth && dateToCompare <= lastDayOfMonth;
	}


	static getCurrentMonth() {
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		const todaysDate = new Date();
	    return monthNames[todaysDate.getMonth()];
	}
}
