import React from 'react';
import { Row, Col } from 'react-bootstrap';
import VideoCard from './video_card';
import Video from '../../models/Video';
import Url from '../../url';
import AppComponent from '../app_component';
import Filters from './Filters';
import SearchBar from './search_bar';
import MobileFilters from './mobile_filters';
import _, { isEmpty } from 'underscore';
import DateUtil from '../../utils/dateUtil';
import ErrorBanner from '../common/ErrorBanner';
import User from '../../models/User';
class SearchContent extends AppComponent {
	constructor(props) {
		super(props);
		this.state = {
			videos: [],
			videoAttributes: {
				yogaStyles: [],
				focusAreas: [],
				bodyParts: [],
				durations: [],
				instructors: [],
			},
			selectedFilters: {
				selectedDurations: this.getDefaultDuration(),
				selectedYogaStyles: this.getDefaultYogaStyle(),
				selectedInstructors: this.getDefaultInstructors(),
				selectedBodyParts: this.getDefaultBodyParts(),
				selectedFocusAreas: this.getDefaultFocusAreas(),
			},
			hasNoSubscribedHistory: false,
			isSubscriptionActive: true,
			textFilter: this.getDefaultTextFilter(),
		};
		this.videoModel = new Video();
		this.userModel = new User();
		_.bindAll(
			this,
			'onCheckboxClick',
			'onSearchBarTextChange',
			'onClearFilter'
		);
	}

	getDefaultDuration() {
		let selectedDurations = window.sessionStorage.getItem('selectedDurations');
		if (isEmpty(selectedDurations)) {
			return [];
		}
		return selectedDurations.split(',');
	}

	getDefaultYogaStyle() {
		let selectedYogaStyles = window.sessionStorage.getItem(
			'selectedYogaStyles'
		);
		if (isEmpty(selectedYogaStyles)) {
			return [];
		}
		return selectedYogaStyles.split(',');
	}

	getDefaultInstructors() {
		let selectedInstructors = window.sessionStorage.getItem(
			'selectedInstructors'
		);
		if (isEmpty(selectedInstructors)) {
			return [];
		}
		return selectedInstructors.split(',');
	}

	getDefaultBodyParts() {
		let selectedBodyParts = window.sessionStorage.getItem('selectedBodyParts');
		if (isEmpty(selectedBodyParts)) {
			return [];
		}
		return selectedBodyParts.split(',');
	}

	getDefaultFocusAreas() {
		let selectedFocusAreas = window.sessionStorage.getItem(
			'selectedFocusAreas'
		);
		if (isEmpty(selectedFocusAreas)) {
			return [];
		}
		return selectedFocusAreas.split(',');
	}

	getDefaultTextFilter() {
		let textFilter = window.sessionStorage.getItem('textFilter');
		if (isEmpty(textFilter)) {
			return '';
		}
		return textFilter;
	}

	componentDidMount() {
		window.getMe(() => {
			this.fetchVideos();
			this.fetchSubscriptionsStatus();
		});
	}

	fetchSubscriptionsStatus() {
		const subscription_details = JSON.parse(window.sessionStorage.getItem('subscription_details'));
		if (subscription_details) {
			this.setState({
					hasNoSubscribedHistory: this.userModel.hasNoSubscribedHistory(subscription_details),
					isSubscriptionActive: this.userModel.isSubscriptionActive(subscription_details)
			});
		} else {
			this.userModel.getSubscriptions((response) => {
				this.setState({
					hasNoSubscribedHistory: this.userModel.hasNoSubscribedHistory(response),
					isSubscriptionActive: this.userModel.isSubscriptionActive(response)
				})	
			});
		}
	}

	fetchVideos() {
		this.videoModel.viewContent(
			{ userId: window.loggedInUser.id },
			(response) => {
				this.setState({
					videos: response.videos,
					videoAttributes: {
						durations: response.durations,
						yogaStyles: response.yoga_styles.sort(),
						instructors: response.instructors.sort(),
						bodyParts: response.body_parts.sort(),
						focusAreas: response.focus_areas.sort(),
					},
				});
			}
		);
	}

	navigate(contentful_video_id) {
		window.sessionStorage.setItem('scrollPosition', window.scrollY);
		const url = `${Url.VIEW_VIDEO_DETAILS}/${contentful_video_id}`;
		this.navigateTo(url);
	}

	setSessionFilter() {
		window.sessionStorage.setItem(
			'selectedDurations',
			this.state.selectedFilters.selectedDurations
		);
		window.sessionStorage.setItem(
			'selectedYogaStyles',
			this.state.selectedFilters.selectedYogaStyles
		);
		window.sessionStorage.setItem(
			'selectedInstructors',
			this.state.selectedFilters.selectedInstructors
		);
		window.sessionStorage.setItem(
			'selectedBodyParts',
			this.state.selectedFilters.selectedBodyParts
		);
		window.sessionStorage.setItem(
			'selectedFocusAreas',
			this.state.selectedFilters.selectedFocusAreas
		);
		window.sessionStorage.setItem('textFilter', this.state.textFilter);
	}

	capitalize(s) {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	}

	updateKeyValue(object, key, value) {
		for (var objectKey in object) {
			if (objectKey === key) {
				if (object[objectKey].includes(value)) {
					object[objectKey] = object[objectKey].filter((val) => val !== value);
				} else {
					object[objectKey] = object[objectKey].concat([value]);
				}
			}
		}
		return object;
	}

	onCheckboxClick(category, value) {
		let filterToUpdate = 'selected' + this.capitalize(category);
		let appliedFilter = this.state.selectedFilters;
		this.setState({
			selectedFilters: this.updateKeyValue(
				appliedFilter,
				filterToUpdate,
				value
			),
		},()=>{window.sessionStorage.setItem(
			filterToUpdate,
			appliedFilter[filterToUpdate]
		);
		});
	}

	onClearFilter() {
		this.setState({
			selectedFilters: {
				selectedDurations: [],
				selectedYogaStyles: [],
				selectedInstructors: [],
				selectedBodyParts: [],
				selectedFocusAreas: [],
			},
			textFilter: "",
		}, () => { this.setSessionFilter() });
	}

	componentDidUpdate() {
		if (!isEmpty(this.state.videos)) {
			window.scrollTo(
				0,
				parseInt(window.sessionStorage.getItem('scrollPosition'))
			);
		}
	}

	getThisMonthVideos(videos) {
		return videos.filter((video) => {
			return DateUtil.isCurrentMonth(video.release_date);
		});
	}

	renderCards() {
		const filter = {
			duration: this.state.selectedFilters.selectedDurations,
			yoga_styles: this.state.selectedFilters.selectedYogaStyles,
			focus_area: this.state.selectedFilters.selectedFocusAreas,
			body_parts: this.state.selectedFilters.selectedBodyParts,
			instructor_name: this.state.selectedFilters.selectedInstructors,
		};
		const displayedVideos = this.videoModel.textSearch(
			this.videoModel.filterVideos(this.state.videos, filter),
			this.state.textFilter
		);
		if (!isEmpty(this.state.videos) && isEmpty(displayedVideos)) {
			return (
				<div className="heading-4 filter-error">
					There are no videos matching those criteria. Please search again.
				</div>
			);
		}
		let thisMonthVideos = this.getThisMonthVideos(displayedVideos);
		if (this.isThereAnyFiltersApplied()) {
			return this.filterAppliedView(displayedVideos);
		}
		return (
			<Row>
				{isEmpty(thisMonthVideos) ? <div /> : this.thisMonthVideoView(thisMonthVideos)}
				{isEmpty(displayedVideos) ? <div /> : this.allVideoView(displayedVideos)}
			</Row>
		);
	}

	filterAppliedView(videos) {
		return <Row>{this.displayVideos(videos)}</Row>;
	}

	isThereAnyFiltersApplied() {
		if (
			isEmpty(this.state.selectedFilters.selectedDurations) &&
			isEmpty(this.state.selectedFilters.selectedBodyParts) &&
			isEmpty(this.state.selectedFilters.selectedFocusAreas) &&
			isEmpty(this.state.selectedFilters.selectedYogaStyles) &&
			isEmpty(this.state.selectedFilters.selectedInstructors) &&
			isEmpty(this.state.textFilter)
		) {
			return false;
		}
		return true;
	}

	allVideoView(videos) {
		return (
			<Col className="mt-5">
				<Row className="search-content-heading heading-2">All Classes</Row>
				<hr />
				<Row>{this.displayVideos(videos)}</Row>
			</Col>
		);
	}

	displayVideos(videos) {
		return videos.map(
			({
				contentful_video_id,
				preview_image,
				yoga_styles,
				instructor_name,
				video_title,
				duration,
				level,
			}) => (
					<VideoCard
						key={contentful_video_id}
						contentfulVideoId={contentful_video_id}
						previewImage={preview_image}
						yogaStyle={yoga_styles}
						instructorName={instructor_name}
						videoTitle={video_title}
						duration={duration}
						level={level}
						onClick={() => this.navigate(contentful_video_id)}
					/>
				)
		);
	}

	thisMonthVideoView(thisMonthVideos) {
		return (
			<Col xs={{ span: 12 }} className="mt-5">
				<Row className="search-content-heading heading-2">
					{DateUtil.getCurrentMonth()} Classes
				</Row>
				<hr />
				<Row className="current-month-videos py-3">
					{this.displayVideos(thisMonthVideos)}
				</Row>
			</Col>
		);
	}

	onSearchBarTextChange(text) {
		this.setState({ textFilter: text }, () => { window.sessionStorage.setItem('textFilter', text) });
	}

	render() {
		if (!this.state.isSubscriptionActive && this.state.hasNoSubscribedHistory) {
			return (
				<div className="search-content">
					<Row className="search-container-no-bg">
						<Col xs={{ span: 10, offset: 1 }}>
							<Row className="d-flex mt-5 justify-content-center">
								<ErrorBanner visible={!this.state.isSubscriptionActive} hasNoSubscribedHistory={this.state.hasNoSubscribedHistory} />
							</Row>
							<div className="heading-4 filter-error mt-5 text-center">
								There are no videos matching those criteria. Please search again.
							</div>
						</Col>
					</Row>
				</div>
			);
		} else {
			return (
				<div className="search-content">
					<Row className="search-container">
						<Col xs={{ span: 10, offset: 1 }}>
							<Row className="d-flex justify-content-center mt-5">
								<ErrorBanner visible={!this.state.isSubscriptionActive} hasNoSubscribedHistory={this.state.hasNoSubscribedHistory} />
							</Row>
							<Row className="d-flex justify-content-center">
								<Col>
									<SearchBar
										value={this.state.textFilter}
										onTextChange={this.onSearchBarTextChange}
									/>
								</Col>
							</Row>
							<Row className="mb-4 mt-2">
								<Col
									sm={12}
									xs={12}
									className="justify-content-end d-block d-md-none mb-2 mt-2"
								>
									<MobileFilters
										videoAttributes={this.state.videoAttributes}
										selectedFilters={this.state.selectedFilters}
										onCheckboxClick={this.onCheckboxClick}
										onClearFilter={this.onClearFilter}
										isFilterApplied={this.isThereAnyFiltersApplied()}
									/>
								</Col>
								<Col
									lg={12}
									md={12}
									className="justify-content-end d-none d-md-block  mb-2 mt-2"
								>
									<Filters
										videoAttributes={this.state.videoAttributes}
										selectedFilters={this.state.selectedFilters}
										onCheckboxClick={this.onCheckboxClick}
										onClearFilter={this.onClearFilter}
										isFilterApplied={this.isThereAnyFiltersApplied()}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="videos">
						<Col
							md={{ span: 10, offset: 1 }} xs={{span: 12}}
							className=" justify-content-center"
						>
							{this.renderCards()}
						</Col>
					</Row>
				</div>
			);
		}
	}
}

export default SearchContent;
