import React from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import _ from 'underscore';
import Url from "../../url";
import AppComponent from "../app_component";
import User from '../../models/User';
import * as Yup from 'yup';
import {ErrorMessage, Field, Formik} from "formik";
import Analytics from "../../models/Analytics";

export default class UpdatePasswordPage extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            successMessage: null,
        };
        _.bindAll(this, 'updatePassword', 'handleClose');
    }

    handleClose() {
        this.navigateTo(Url.ACCOUNT);
    };

    updatePassword(fields, formikAction) {
        let {current_password, new_password} = fields;
        const user = new User();
        user.updatePassword({ current_password, new_password })
            .then(response => {
                window.storeToken(response.data.idToken);
                window.setRefreshToken(response.data.refreshToken);
                window.setTokenExpiry(response.data.tokenExpiresAt);
                formikAction.resetForm();
                Analytics.captureEvent('updatePassword');
                this.setState({ successMessage : 'Password updated successfully'})
            }).catch(error => {
                let data = error.response.data;
                if(data.error && data.error === 'INCORRECT_CURRENT_PASSWORD') {
                    formikAction.setFieldError('current_password', 'Current Password entered is incorrect')
                }
                this.setState({successMessage: null})
            });
    }

    getValidationSchema() {
        return Yup.object().shape({
            current_password: Yup.string().required('Please enter current password'),
            new_password: Yup.string().required('Please enter new password')
                .matches(/^([a-zA-Z0-9@*#$%^&-_!+=]{8,})$/g, 'Password must be at least 8 characters, no spaces'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('new_password'), null], 'Passwords do not match')
                .required('Please enter confirm password'),
        })
    }

    validateCurrentPassword(e, handleBlur, getFieldHelpers) {
        handleBlur(e);
        let value = e.currentTarget.value
        if (value) {
            let user = new User();
            user.validatePassword({password: value})
                .then(response => {
                    window.storeToken(response.data.idToken);
                    window.setRefreshToken(response.data.refreshToken);
                    window.setTokenExpiry(response.data.tokenExpiresAt);
                }).catch(error => {
                let data = error.response.data;
                if(data.error && data.error === 'INCORRECT_PASSWORD') {
                    let helper = getFieldHelpers('current_password');
                    helper.setError('Current Password entered is incorrect');
                }
            })

        }
    }

    renderSuccess() {
        if (!this.state.successMessage) {
            return <span />;
        }
        return <span className='text-success'>{this.state.successMessage}</span>;
    }

    render() {
        return (
            <div className='update-password-background mb-n5'>
                <Container className="reset-password-container">
                    <Row className="container-background-color justify-content-center">
                        <Col md={{ span: 6 }} xl={{ span: 5 }} className='text-center'>
                            <Card className="text-center p-3 card-box-shadow">
                                <Card.Body>
                                    <div className="pb-3 title">
                                        Update Password
                                    </div>
                                    {this.renderSuccess()}
                                    <Formik
                                        initialValues={{
                                            current_password: '',
                                            new_password: '',
                                            confirm_password: ''
                                        }}
                                        validationSchema={this.getValidationSchema}
                                        onSubmit={this.updatePassword}
                                        validateOnBlur={false}
                                    >
                                    {({ errors, status, touched, handleSubmit, handleBlur, getFieldHelpers}) => (
                                        <Form className='pt-4' onSubmit={handleSubmit}>
                                            <Form.Group controlId="formCurrentPassword">
                                                <Field
                                                    name="current_password"
                                                    type="password"
                                                    className={'form-control' + (errors.current_password && touched.current_password ? ' is-invalid' : '')}
                                                    placeholder='Current Password'
                                                    onBlur={(e) => this.validateCurrentPassword(e, handleBlur, getFieldHelpers)}
                                                />
                                                <ErrorMessage name="current_password" component="div" className="invalid-feedback" />
                                            </Form.Group>
                                            <Form.Group controlId="formNewPassword">
                                                <Field
                                                    name="new_password"
                                                    type="password"
                                                    className={'form-control' + (errors.new_password && touched.new_password ? ' is-invalid' : '')}
                                                    placeholder='New Password'
                                                />
                                                <ErrorMessage name="new_password" component="div" className="invalid-feedback" />
                                            </Form.Group>
                                            <Form.Group controlId="formConfirmNewPassword">
                                                <Field
                                                    name="confirm_password"
                                                    type="password"
                                                    className={'form-control' + (errors.confirm_password && touched.confirm_password ? ' is-invalid' : '')}
                                                    placeholder='Confirm New Password'
                                                />
                                                <ErrorMessage name="confirm_password" component="div" className="invalid-feedback" />
                                            </Form.Group>
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                block
                                                className="my-4 request-reset-button cursor-pointer"
                                            >
                                                UPDATE PASSWORD
                                            </Button>
                                        </Form>
                                    )}
                                    </Formik>
                                    <a onClick={this.handleClose} className="back-login cursor-pointer">
                                        Back to My Account
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
         );
    }
}