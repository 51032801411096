import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
	Breadcrumb,
	Button,
	OverlayTrigger,
	Tooltip,
	Modal,
} from 'react-bootstrap';
import _ from 'underscore';
import AppComponent from '../app_component';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/instagram.svg';
import twitter from '../../assets/images/twitter.svg';
import website from '../../assets/images/website.svg';
import spotify from '../../assets/images/spotify.svg';
import play_thumbnail from '../../assets/images/play.svg';
import lotusImage from '../../assets/images/flower.svg';
import Url from '../../url';
import Video from '../../models/Video';
import User from '../../models/User';
import Analytics from '../../models/Analytics';
import ErrorBanner from '../common/ErrorBanner';

export default class VideoDetailsPage extends AppComponent {
	constructor(props) {
		super(props);
		this.state = {
			model: {},
			contentfulVideoId: window.location.pathname.split('/')[2],
			video: new Video(),
			errors: null,
			isSubscriptionActive: true,
			hasNoSubscriptionHistory: false,
			showModal: false
		};
		_.bindAll(this, 'openPlayer', 'handleModalClose', 'aboutInstructor');
	}

	componentDidMount() {
		window.getMe(() => {
			this.fetchVideoDetails();
			this.fetchSubscriptionStatus();
		});
	}


	fetchSubscriptionStatus() {
		const user = new User(window.loggedInUser);
		const subscription_details = JSON.parse(window.sessionStorage.getItem('subscription_details'));
		if (subscription_details) {
			if (!user.isSubscriptionActive(subscription_details)) {
				this.setState({
					errors: { message: 'NOT_SUBSCRIBED_TO_VIEW_THIS_VIDEO' },
					isSubscriptionActive: false,
					hasNoSubscriptionHistory: user.hasNoSubscribedHistory(subscription_details)
				});
			}
		} else {
			user.getSubscriptions((response) => {
				if (!user.isSubscriptionActive(response)) {
					this.setState({
						errors: { message: 'NOT_SUBSCRIBED_TO_VIEW_THIS_VIDEO' },
						isSubscriptionActive: false,
						hasNoSubscriptionHistory: user.hasNoSubscribedHistory(response)
					});
				}
			});
		}
	}

	componentDidUpdate() {
		window.scrollTo(0, 0);
	}

	fetchVideoDetails() {
		this.state.video.getVideoDetails(
			{
				loggedInUserId: window.loggedInUser.id,
				contentfulVideoId: this.state.contentfulVideoId,
			},
			(response) => {
				const model = {
					instructorName: response.instructor_name,
					instructorProfilePic: response.profile_picture,
					instructorProfile: response.instructor_contentful_id,
					aboutInstructor: response.instructor_bio,
					instructorLinks: response.social_media,
					videoCategory: response.category[0],
					videoLevel: response.level,
					videoTitle: response.video_title,
					videoDuration: response.duration,
					videoLink: response.vimeo_id,
					videoThumbnail: response.preview_image,
					requiredProps: response.required_props,
					optionalProps: response.optional_props,
					aboutVideo: response.video_description,
				};
				this.setState({ model });
				Analytics.capturedVideoDetailOpened(this.state.contentfulVideoId);
			}
		);
	}

	openPlayer() {
		const user = new User(window.loggedInUser);
		const isSusbcribed = JSON.parse(window.sessionStorage.getItem(this.state.contentfulVideoId));
        if (isSusbcribed) {
			const url = `${Url.VIDEO_PLAYER}/${this.state.contentfulVideoId}/${this.state.model.videoLink}`;
			this.navigateTo(url);
		} else {
			user.subscribed(this.state.contentfulVideoId)
			.then((response) => {
				window.sessionStorage.setItem(this.state.contentfulVideoId, JSON.stringify(true));
				const url = `${Url.VIDEO_PLAYER}/${this.state.contentfulVideoId}/${this.state.model.videoLink}`;
				this.navigateTo(url);
			})
			.catch((error) => {
				window.sessionStorage.removeItem(this.state.contentfulVideoId);
				this.setState({ errors: { message: "NOT_SUBSCRIBED_TO_VIEW_THIS_VIDEO" }, showModal: true });
			});

		}
	}

	aboutInstructor() {
		this.navigateTo(`${Url.INSTRUCTOR_DETAIL}/${this.state.model.instructorProfile}`,
			{
				videoTitle: this.state.model.videoTitle,
				videoId: this.state.contentfulVideoId
			});
	}

	renderRequiredProps() {
		const totalPropCount = this.state.model.requiredProps.length;
		const propsNeeded = this.state.model.requiredProps;
		const propComponent = [];
		for (let eachProp = 0; eachProp < totalPropCount; eachProp++) {
			propComponent.push(
				<OverlayTrigger
					placement="top"
					delay={{ show: 250, hide: 300 }}
					key={`propsNeededeachProp-${eachProp}`}
					overlay={
						<Tooltip id={`tooltip-${eachProp}`}>
							{propsNeeded[eachProp]['name']}
						</Tooltip>
					}
				>
					<Image
						src={propsNeeded[eachProp]['url']}
						key={eachProp}
						fluid
						className="mr-4 icon-width"
					/>
				</OverlayTrigger>
			);
		}
		return propComponent;
	}

	renderOptionalProps() {
		const totalPropCount = this.state.model.optionalProps.length;
		const propsNeeded = this.state.model.optionalProps;
		const propComponent = [];
		for (let eachProp = 0; eachProp < totalPropCount; eachProp++) {
			propComponent.push(
				<OverlayTrigger
					placement="top"
					delay={{ show: 250, hide: 300 }}
					key={`propsNeededeachProp-${eachProp}`}
					overlay={
						<Tooltip id={`tooltip-${eachProp}`}>
							{propsNeeded[eachProp]['name']}
						</Tooltip>
					}
				>
					<Image
						src={propsNeeded[eachProp]['url']}
						key={eachProp}
						fluid
						className="mr-4 icon-width"
					/>
				</OverlayTrigger>
			);
		}
		return propComponent;
	}

	renderSocialMediaLinks() {
		const links = {
			facebook: facebook,
			twitter: twitter,
			instagram: instagram,
			website: website,
			spotify: spotify,
		};
		const totalSocialMediaLinks = this.state.model.instructorLinks;
		const socialMediaLinkComponent = [];

		Object.keys(totalSocialMediaLinks).forEach(function (key) {
			if (totalSocialMediaLinks[key]) {
				socialMediaLinkComponent.push(
					<a
						href={totalSocialMediaLinks[key]}
						target="_blank"
						rel="noopener noreferrer"
						className="mr-3 cursor-pointer"
						key={key}
					>
						<Image
							src={links[key]}
							fluid
							roundedCircle
							className="social-link-image"
						/>
					</a>
				);
			}
		});
		return socialMediaLinkComponent;
	}

	breadcrumbMobileView() {
		return (
			<Col xs={{ span: 12 }} className="d-block d-md-none">
				<Row>
					<Breadcrumb>
						<Breadcrumb.Item onClick={() => this.navigateTo(Url.PRACTICE)}>
							Practice
						</Breadcrumb.Item>
						<Breadcrumb.Item className="breadcrumb-active-color" active>
							{this.state.model.videoTitle}
						</Breadcrumb.Item>
					</Breadcrumb>
				</Row>
			</Col>
		);
	}

	breadcrumbDesktopView() {
		return (
			<Row className="d-none d-md-block ">
				<Breadcrumb>
					<Breadcrumb.Item onClick={() => this.navigateTo(Url.PRACTICE)}>
						Practice
					</Breadcrumb.Item>
					<Breadcrumb.Item className="breadcrumb-active-color" active>
						{this.state.model.videoTitle}
					</Breadcrumb.Item>
				</Breadcrumb>
			</Row>
		);
	}

	instructorDetailsMobileView() {
		return (
			<Row className="pt-4 d-block d-md-none instructor-detail-mobile">
				<Col className="d-flex justify-content-center">
					<Image
						src={this.state.model.instructorProfilePic}
						roundedCircle
						className="avatar-size cursor-pointer"
						onClick={this.aboutInstructor}
					/>
				</Col>
				<Col
					className="d-flex justify-content-center heading-2 cursor-pointer"
					onClick={this.aboutInstructor}
				>
					{this.state.model.instructorName}
				</Col>
				<Col className="d-flex justify-content-center mt-2 mb-4">
					{this.renderSocialMediaLinks()}
				</Col>
				<Col className="d-flex">
					<Row className="instructor-summary heading-4 text-center">
						{this.state.model.aboutInstructor}
					</Row>
				</Col>
				<Col className="d-flex justify-content-center">
					<Row>
						<a
							className="heading-4 know-more cursor-pointer font-weight-bold"
							onClick={this.aboutInstructor}
						>
							Know more
							</a>
					</Row>
				</Col>
			</Row>
		);
	}

	instructorDetailsDesktopView() {
		return (
			<Row className="pt-4 d-none d-md-block instructor-detail">
				<Col>
					<Row className="instructor">
						<Col className="instructor pl-0" md={{ span: 12 }}>
							<Image
								src={this.state.model.instructorProfilePic}
								roundedCircle
								className="avatar-size cursor-pointer mr-4"
								onClick={this.aboutInstructor}
							/>
							<div className="instructor">
								<Row className=" d-none d-lg-block">
									<Col
										className="heading-3 cursor-pointer mt-1"
										onClick={this.aboutInstructor}
									>
										{this.state.model.instructorName}
									</Col>
								</Row>
								<Row className=" d-none d-lg-block">
									<Col className="my-1">{this.renderSocialMediaLinks()}</Col>
								</Row>
							</div>
							<div className="instructor">
								<Row className="d-md-block d-lg-none">
									<Col
										className="heading-3 cursor-pointer mt-1"
										onClick={this.aboutInstructor}
									>
										{this.state.model.instructorName}
									</Col>
								</Row>
							</div>
							<div>
								<Row className="d-md-block d-lg-none">
									<Col className="my-1">{this.renderSocialMediaLinks()}</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Col>
				<Col className="mt-4">
					<Row>
						<label className="instructor-summary heading-4">
							{this.state.model.aboutInstructor}
						</label>
						<a
							className="heading-4 know-more cursor-pointer font-weight-bold"
							onClick={this.aboutInstructor}
						>
							Know more
						</a>
					</Row>
				</Col>
			</Row>
		);
	}

	isVideoDurationLessThan10Mins = (duration) => {
		return duration.split(" ")[0].length === 1;
	}

	formatDuration = (duration) => {
		return this.isVideoDurationLessThan10Mins(duration) ? `0${duration}` : duration;
	}

	handleModalClose() {
		this.setState({ showModal: false });
	}

	render() {
		if (_.isEmpty(this.state.model)) {
			return <div />;
		}
		return (
			<Container fluid={true} className="video-detail-container mt-5 pt-5">
				<Modal
					show={!_.isEmpty(this.state.errors) && this.state.showModal}
					onHide={this.handleModalClose}
					backdrop="static"
					centered
				>
					<Modal.Body>
						{this.renderError('message', 'modal-message')}
					</Modal.Body>
					<Modal.Footer className="justify-content-center border-0">
						<Button
							variant="outline-danger"
							className="modal-btn-purple px-5"
							onClick={this.handleModalClose}
						>
							OK
						</Button>
					</Modal.Footer>
				</Modal>

				<Col md={{ offset: 1, span: 10 }}>
					<Row className="d-flex justify-content-center mb-3">
						<ErrorBanner visible={!this.state.isSubscriptionActive} hasNoSubscribedHistory={this.state.hasNoSubscriptionHistory} />
					</Row>
					{this.breadcrumbMobileView()}
					<Row>
						<Col
							lg={{ span: 6 }}
							md={{ span: 6 }}
							xs={{ span: 12 }}
							sm={{ span: 12 }}
						>
							<Row
								className="video-thumbnail cursor-pointer"
								onClick={this.openPlayer}
							>
								<Col xs={{ span: 12 }}>
									<Row>
										<Image
											src={this.state.model.videoThumbnail}
											className="r-image"
										/>
										<Image src={play_thumbnail} className="play-thumbnail" />
									</Row>
								</Col>
							</Row>
							<Row className="about-body d-none d-md-block">
								<div className="heading-2">Video Description</div>
							</Row>
							<Row className="d-none d-md-block">
								<div className="heading-4 about-description">
									<div className="text-pre-wrap" dangerouslySetInnerHTML={{ __html: this.state.model.aboutVideo }} />
								</div>
							</Row>
						</Col>
						<Col lg={{ span: 6 }} md={{ span: 6 }} xs={{ span: 12 }} className="title-body">
							{this.breadcrumbDesktopView()}
							<Row className="video-summary">
								<Col className="video-title heading-1">
									{this.state.model.videoTitle}
								</Col>
							</Row>
							<Row className="heading-3 level-row mt-4">
								<div className="mr-4">
									<span className="inline-block">
										<Image
											src={lotusImage}
											rounded
											className="lotus-image mb-1 inline-block"
											/>
										<p className="ml-2 inline-block">
											{this.state.model.videoLevel}
										</p>
									</span>
								</div>
								<div className="mr-4">
									{this.formatDuration(this.state.model.videoDuration)}
								</div>
								<div className="mr-4">
									{this.state.model.videoCategory}
								</div>
							</Row>
							<Row className="pb-4 pt-3 ">
								<Button
									className="practice-button cursor-pointer"
									onClick={this.openPlayer}
								>
									Start Practice
									</Button>
							</Row>
							<Row className="about-body d-block d-md-none border-top">
								<Col md={{ span: 12 }}>
									<Row className="heading-2">Video Description</Row>
								</Col>
							</Row>
							<Row className="d-block d-md-none">
								<Col md={{ span: 12 }}>
									<Row className="heading-4 about-description mb-4">
										<div className="text-pre-wrap" dangerouslySetInnerHTML={{ __html: this.state.model.aboutVideo }} />
									</Row>
								</Col>
							</Row>
							<Row className="props-row">
								<Col className="heading-2" md={{ span: 12 }}>
									<Row className="mb-2">Required Props</Row>
									<Row>
										{(() => {
											if (
												this.state.model.requiredProps &&
												this.state.model.requiredProps.length > 0
											) {
												return this.renderRequiredProps();
											}
											return (
												<label className="heading-4">No Props Needed</label>
											);
										})()}
									</Row>
								</Col>
							</Row>
							{(() => {
								if (
									this.state.model.optionalProps &&
									this.state.model.optionalProps.length > 0
								) {
									return (
										<Row className="props-row">
											<Col className="heading-2" md={{ span: 12 }}>
												<Row className="mb-2">Optional Props</Row>
												<Row>{this.renderOptionalProps()}</Row>
											</Col>
										</Row>
									);
								}
							})()}
							{this.instructorDetailsDesktopView()}
							{this.instructorDetailsMobileView()}
						</Col>
					</Row>
				</Col>
			</Container>
		);
	}
}
