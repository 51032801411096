import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CardDetails from './CardDetails';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Stripe = (props) => {
	return (
		<Elements stripe={stripePromise}>
			<InjectedCardDetails
				holderEmail={props.holderEmail}
				handleClose={props.handleClose}
				handleShow={props.handleShow}
				show={props.show}
			/>
		</Elements>
	);
};

const InjectedCardDetails = (props) => {
	return (
		<ElementsConsumer>
			{({ stripe, elements }) => (
				<CardDetails
					stripe={stripe}
					elements={elements}
					holderEmail={props.holderEmail}
					handleClose={props.handleClose}
					handleShow={props.handleShow}
					show={props.show}
				/>
			)}
		</ElementsConsumer>
	);
};

export default Stripe;
