import React from "react";
import _ from 'underscore';
import {
    Form,
    Button,
    Card,
    Image,
    Row,
    Col
} from "react-bootstrap";
import AppComponent from "../app_component";
import Url from "../../url";
import logoImage from '../../assets/images/zenevate_logo.png';
import User from "../../models/User";
import Analytics from '../../models/Analytics';

export default class LoginPage extends AppComponent {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errors: null
        };
        _.bindAll(this, 'submit', 'resetPassword', 'changeState', 'onSuccess');
    }

    componentDidMount() {
        if (window.getJwtToken()) {
            this.navigateTo(Url.CONTENT);
        }
    }

    changeState(event) {
        let fieldValue = event.target.value;
        let fieldName = event.target.name;
        this.setState({ [fieldName]: fieldValue });
    }

    onSuccess(response) {
        const { data } = response
        const user = {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email
        };
        window.storeToken(data.idToken);
        window.setRefreshToken(data.refreshToken);
        window.setTokenExpiry(data.tokenExpiresAt);
        window.userInit(user);
        window.renderNavBar();
        Analytics.identifyUserLogin(
            data.id,
            `${data.firstName} ${data.lastName}`,
            data.email,
            data.subscription_details
        );
        this.navigateTo(Url.CONTENT);
    }

    onFail(error) {
        if (error.response) {
            this.setState({ errors: error.response.data.error });
        }
    }

    login() {
        const loginData = {
            email: this.state.email,
            password: this.state.password
        };
        let user = new User();
        user.login(loginData)
            .then(response => this.onSuccess(response))
            .catch(error => this.onFail(error));
    }

    submit = () => { this.login(); };

    onEnter = (e) => { if (e.keyCode === 13) { this.login(); } };

    resetPassword() {
        this.navigateTo(Url.RESET_PASSWORD);
    }

    render() {
        var subscriptionUrl = "https://zenevate.com/staging/pricing";
        if (process.env.REACT_APP_ENV === 'production') {
            subscriptionUrl = "https://zenevate.com/pricing"
        }
        return (
            <div className="login-page my-n5">
                <div className="container">
                    <Row>
                        <Col sm={{ offset: 4, span: 4 }} xl={{ offset: 1 }} className="logo" >
                            <Image src={logoImage} className="logo-height"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={{ offset: 7, span: 5 }} sm={{ span: 8, offset: 2 }} md={{ offset: 3, span: 6 }}>
                            <Card>
                                <Card.Body>
                                    <Card.Title className="sign-in-label font-size-1">Sign in</Card.Title>
                                    <Card.Subtitle className="not-registered font-size-3">Not yet registered?
                                    <Card.Link href={subscriptionUrl} target="__blank" className="font-size-3">
                                            &nbsp;Sign up now</Card.Link>
                                    </Card.Subtitle>
                                    <label className="login-error">{this.renderError('message')}</label>
                                    <Form className="login-form">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control type="email" name='email' placeholder="Email" onChange={this.changeState} className="font-size-4"/>
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Control onKeyDown={this.onEnter} type="password" name='password' placeholder="Password" value={this.state.password} onChange={this.changeState} className="font-size-4"/>
                                        </Form.Group>
                                        <Form.Group className="forgot-password">
                                            <Form.Label onClick={this.resetPassword} className="cursor-pointer font-size-4">Forgot Password?</Form.Label>
                                        </Form.Group>
                                        <Button variant="primary" type="button" onClick={this.submit} className="font-size-3">
                                            LOGIN
                                    </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

}
