import React from 'react';
import searchIcon from '../../assets/images/search.svg';
import _ from 'underscore';
import { Button } from 'react-bootstrap';

class SearchBar extends React.Component {
	constructor(props) {
		super(props);
		_.bindAll(this, 'onTextChange');
	}

	onTextChange(event) {
		this.props.onTextChange(event.target.value);
	}

	render() {
		return (
			<div className="input-group search-input-group mt-5">
				<div className="input-group-prepend heading-5">
					<Button
						type="submit"
						bsPrefix="btn btn-link text-warning"
						className="pl-0"
					>
						<img
							src={searchIcon}
							alt="search"
							className="search-icon"
						/>
					</Button>
				</div>
				<input
					value={this.props.value}
					type="search"
					placeholder="Search here..."
					className="form-control heading-1-regular border-0 search-input"
					onChange={this.onTextChange}
				/>
			</div>
		);
	}
}

export default SearchBar;
