import React from "react";
import {Row, Col} from "react-bootstrap";
import logo from '../../assets/images/zenevate_logo.png';
import facebook from '../../assets/images/white_facebook.svg';
import instagram from '../../assets/images/white_instagram.svg';
import twitter from '../../assets/images/white_twitter.svg';
import Url from "../../url";
import _ from "underscore";
import { createBrowserHistory } from 'history';
import AppComponent from "../app_component";

export default class Footer extends AppComponent{

    constructor(props) {
        super(props);
        _.bindAll(this, 'redirectTo');
    }

    redirectTo =(url)=>{
        const history = createBrowserHistory();
        history.push(url);
        window.location.reload(true);
    }

    openWindow(url) {
        window.open('http://zenevate.com'+url,'MyWindow','width=600','height=300'); 
        return false;
     } 
    
    render() {
        const year = new Date().getFullYear();
        return (
            <footer className="zenevate-footer pt-2 pb-2" expand="lg">
                <div className='mt-2 container-fluid'>
                    <Col md={{ span:10, offset: 1 }}>
                        <Row className="py-4 ">
                            <Col md={12} lg={{ span:2, offset: 1 }} className="mission-title pl-0 text-lg-right">
                                Our mission :
                            </Col>
                            <Col md={12} lg={8} className="mission-description pl-0">
                                Expand access to yoga and wellness to our global communities while 
                                providing yoga instructors and wellness practitioners easy video sharing 
                                and business capabilities for greater impact.
                            </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={{ span:10, offset: 1 }} className="border-bottom border-white"></Col>
                        </Row>
                        <Row className="mt-5 mb-2 align-items-center mobile-view-none">
                            <Col md={4}>
                                <Row className="border-bottom border-white pt-3 pb-3 mr-2">
                                  <Col className="pl-0 pb-2" xl={6} lg={6}>
                                    <img className="zenevate-logo" src={logo}  alt="Zenevate Logo"/>
                                  </Col>
                                  <Col className="pl-0 pl-lg-2 pb-2 md-lg-align-end" xl={6} lg={6}>
                                  <a href="https://facebook.com/zenevate" target="_blank">
                                      <img className="social-logo mr-2" src={facebook}  alt="facebook"/>
                                    </a>  
                                    <a href="https://www.instagram.com/zenevate/" target="_blank">
                                      <img className="social-logo mr-2" src={instagram}  alt="instagram"/>
                                    </a>
                                    <a href="https://twitter.com/zenevate" target="_blank">
                                     <img className="social-logo mr-2" src={twitter}  alt="twitter"/>
                                    </a> 
                                  </Col>
                                </Row>
                                <Row >
                                    <Col className="pl-0 mt-2 mb-2 footer-contact"> 
                                    <p><a href="https://www.zenevate.com/contact-us" target="_blank">
                                    Contact us
                                    </a></p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={0} className="pl-0">
                                         <p className="footer-link border-0 text-sm-left">
                                         <a  onClick={()=>{this.openWindow('/privacy-policy')}}>
                                            PRIVACY POLICY 
                                            </a>
                                         </p>
                                    </Col>
                                    <Col >
                                       <p className="footer-link text-sm-left pl-3">
                                       <a   onClick={()=>{this.openWindow('/terms-of-service')}}>
                                          TERMS & CONDITIONS
                                          </a> 
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={8}>
                               <Row>
                               { window.loggedInUser ? 
                                    <Col xl={2} className="pr-0 pl-0 col-md">
                                      <p className="footer-link" onClick={()=>{this.redirectTo(Url.ACCOUNT)}}>
                                        MY ACCOUNT 
                                      </p>
                                    </Col>
                               : null}
                                    <Col xl={2} className="pr-0 pl-0 col-md">
                                       <p className="footer-link" ><a href="https://www.zenevate.com/zenevate-instructors" target="_blank">
                                         INSTRUCTORS
                                       </a></p>
                                    </Col>
                                    <Col xl={2} className="pr-0 pl-0 col-md">
                                      <p className="footer-link">
                                      <a href="https://www.zenevate.com/about-us" target="_blank">
                                       ABOUT US 
                                      </a></p>
                                    </Col>
                                    <Col xl={3} className="pr-0 pl-0 col-md">
                                       <p className="footer-link">
                                       <a href="https://www.zenevate.com/teach-on-zenevate" target="_blank">
                                       TEACH ON ZENEVATE 
                                       </a></p>
                                    </Col>
                                    <Col xl={2} className="pr-0 pl-0 col-md"><p className="footer-link"><a href="https://www.zenevate.com/pricing" target="_blank">SUBSCRIBE</a> </p></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-5 mb-2 align-items-center mobile-view ">
                           <Col md={12}>
                               <Row className="border-bottom border-white pb-4">
                                    { window.loggedInUser ? 
                                    <Col xs={12}><p className="footer-link border-0 text-center" onClick={()=>{this.redirectTo(Url.ACCOUNT)}}>MY ACCOUNT</p></Col>
                                    : null}
                                    <Col xs={12}><p className="footer-link border-0 text-center">
                                    <a href="https://www.zenevate.com/zenevate-instructors" target="_blank">INSTRUCTORS</a></p></Col>
                                    <Col xs={12}><p className="footer-link border-0 text-center" >
                                    <a href="https://www.zenevate.com/about-us" target="_blank">ABOUT US</a></p></Col>
                                    <Col xs={12}><p className="footer-link border-0 text-center">
                                    <a href="https://www.zenevate.com/teach-on-zenevate" target="_blank">TEACH ON ZENEVATE</a></p></Col>
                                    <Col xs={12}><p className="footer-link  border-0 text-center">
                                    <a href="https://www.zenevate.com/pricing" target="_blank">SUBSCRIBE</a></p></Col>
                               </Row>
                            </Col>
                            <Col md={12}>
                                <Row className="pt-3 pb-3">
                                  <Col xs={12} className="d-flex justify-content-center mb-3">
                                    <img className="zenevate-logo px-4" src={logo}  alt="Zenevate"/>
                                  </Col>
                                  <Col xs={12} className="d-flex justify-content-center mb-2"> 
                                    <a href="https://facebook.com/zenevate" target="_blank">
                                      <img className="social-logo mr-2" src={facebook} alt="facebook mobile"/>
                                    </a>  
                                    <a href="https://www.instagram.com/zenevate/" target="_blank">
                                      <img className="social-logo mr-2" src={instagram}  alt="instagram mobile"/>
                                    </a>
                                    <a href="https://twitter.com/zenevate" target="_blank">
                                     <img className="social-logo " src={twitter}  alt="twitter mobile"/>
                                    </a> 
                                  </Col>
                                </Row>
                                <Row >
                                    <Col className="mb-3 footer-contact text-center"> 
                                     <p>
                                     <a href="https://www.zenevate.com/contact-us" target="_blank">Contact us</a>
                                     </p>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-white">
                                    <Col xs={12} >
                                      <p className="footer-link border-0 text-center">
                                        <a  onClick={()=>{this.openWindow('/privacy-policy')}}>
                                         PRIVACY POLICY 
                                        </a>
                                      </p>
                                    </Col>
                                    <Col xs={12}>
                                       <p className="footer-link border-0 text-center">
                                       <a onClick={()=>{this.openWindow('/terms-of-service')}}>
                                          TERMS & CONDITIONS
                                          </a>
                                        </p>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-center text-center copyright">
                            <p>Copyright &copy; {year} Zenevate, LLC, All Rights Reserved</p>
                        </Row>
                    </Col>
                </div>
            </footer>
        );
        
    }
}