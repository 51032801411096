import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export default class ProfileDetails extends React.Component {

	render() {
		return (
			<Row>
				<Form.Label column lg={3} sm={4} className="details-heading heading-3">
					Profile Details
				</Form.Label>
				<Col sm={8} lg={9}>
					<Row>
						<Col lg={8} xs={6}>
							<p className="details pt-2">{window.loggedInUser.firstName + ' ' +window.loggedInUser.lastName}</p>
						</Col>
						<Col lg={4} xs={6}>
							<Form.Control className="link d-none" plaintext readOnly defaultValue="Edit"/>
						</Col>
					</Row>
					<Row>
						<Col lg={8} xs={12}>
							<p className="details pt-2 heading-5">{window.loggedInUser.email}</p>
						</Col>
						<Col lg={4} xs={12}>
							<Form.Control className="link d-none heading-5" plaintext readOnly defaultValue="Update email address"/>
						</Col>
					</Row>
					<Row className="pt-2">
						<Col lg={8} xs={12}>
							<p className="details heading-5">Password: **********</p>
						</Col>
						<Col lg={4} xs={12}>
							<p><a className="link cursor-pointer heading-5" onClick={this.props.onUpdatePassword}>
									Change password
								</a>
							</p>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}
