import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import StripeSubscription from '../../models/StripeSubscription';
import Analytics from '../../models/Analytics';
class CancelSubscription extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
		};
	}

	showSpinner = () => {
		this.refs.cancel_subscription_button.setAttribute('disabled', true);
		this.refs.cancel_sub_spinner.setAttribute(
			'class',
			'spinner-border spinner-border-sm'
		);
	};

	hideSpinner = () => {
		this.refs.cancel_sub_spinner.removeAttribute('class');
		this.refs.cancel_subscription_button.removeAttribute('disabled');
	};

	handleConfirmCancellation = () => {
		this.setState({ error: '' });
		this.showSpinner();

		StripeSubscription.cancelSubscription(
			this.props.subscriptionId,
			window.loggedInUser.id
		)
			.then((result) => {
				this.hideSpinner();
				this.props.handleClose(result.data.cancel_on);
				Analytics.captureEvent('cancelSubscription');
			})
			.catch((error) => {
				this.hideSpinner();
			});
	};

	render() {
		return (
			<Modal show={this.props.show} onHide={() => this.props.handleClose(null)} className="backdrop-image">
				<Modal.Body className="text-center">
					<div className={this.state.error ? "card-errors" : "d-none"} role="alert">
						{this.state.error}
						<br />
					</div>
					<div className="heading-5 modal-message">
						If you cancel your subscription, you will no longer have access to
						the {this.props.channelName} channel after the end of your current
						billing period. Your viewing history will remain.
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-center border-0">
					<Button variant="light" onClick={() => this.props.handleClose(null)}>
						Go Back
					</Button>
					<Button
						ref="cancel_subscription_button"
						variant="outline-danger"
						className="modal-btn-red"
						onClick={this.handleConfirmCancellation}
					>
						<span ref="cancel_sub_spinner" role="status" aria-hidden="true" />{' '}
						Confirm Cancellation
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default CancelSubscription;
