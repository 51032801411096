import React from 'react';
import lotusImage from '../../assets/images/lotus.svg';
import { Row, Col, Card, Image } from 'react-bootstrap';

const VideoCard = (props) =>{

	const isVideoDurationLessThan10Mins = (duration) => {
		return duration.split(" ")[0].length === 1;
	}

	const formatDuration = (duration) => {
		return isVideoDurationLessThan10Mins(duration) ? `0${duration}` : duration;
	}

		return (
			<Col
				xs={{ span: 12 }}
				sm={{ span: 10, offset: 1 }}
				md={{ span: 6, offset: 0 }}
				lg={{span: 6 }}
				xl={{ span: 4 }}
			>
				<div className="card-box cursor-pointer">
					<Card bsPrefix="card video-card" onClick={props.onClick}>
						<Card.Img src={props.previewImage} alt={props.videoTitle} variant="top" />
						<Card.Body>
							<Row>
								<Col
									xs={{ span: 8 }}
									sm={{ span: 9 }}
									className="video-content-left pl-2 pl-md-3"
								>
									<div className="card-instructor-name heading-6">{props.instructorName}</div>
									<div className="card-video-title heading-5">{props.videoTitle}</div>
									<div className="card-video-yoga-style heading-6">
										{props.yogaStyle}
									</div>
								</Col>
								<Col
									xs={{ span: 4 }}
									sm={{ span: 3 }}
									className="video-content-right px-0"
								>
									<Row className="card-video-level">
										<Col xs={{ span: 12 }} className="px-0">
											<Image
                                                className="card-video-level-image mb-2"
												alt="lotus"
												src={lotusImage}
											/>
											<span className="card-video-level-text heading-6">{props.level}</span>
										</Col>
									</Row>
									<Row className="card-video-duration ">
										<Col xs={{ span: 12 }} className="heading-5 font-weight-bold">{formatDuration(props.duration)}</Col>
									</Row>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</div>
			</Col>
		);
	}

export default VideoCard;