import BaseModel from './BaseModel';
import axios from '../axios/index';
import axiosOriginal from 'axios';
import { getApiUrl } from '../utils/apiUrls';
import _, {
	isEmpty,
	isArray,
	isUndefined,
	intersection,
	isNull,
} from 'underscore';

export default class Video extends BaseModel {
	viewContent({ userId }, callback) {
		axios.get(getApiUrl('INSTRUCTOR_VIDEOS', { userId })).then((response) => {
			callback(response.data);
		});
	}

	getVideoDetails({ loggedInUserId, contentfulVideoId }, callback) {
		axios
			.get(
				getApiUrl('VIEW_VIDEO_DETAILS', {
					userId: loggedInUserId,
					contentfulVideoId,
				})
			)
			.then((response) => {
				callback(response.data);
			})
			.catch((error) => console.log('error in getVideoDetails', error));
	}

	filterVideos(videos, filters) {
		if (
			isEmpty(filters.duration) ||
			isEmpty(filters.yoga_style) ||
			isEmpty(filters.focus_area) ||
			isEmpty(filters.body_parts) ||
			isEmpty(filters.instructor_name)
		) {
			return videos.filter((video) => {
				for (var key in filters) {
					if (isEmpty(filters[key])) {
						continue;
					}
					if (isArray(video[key])) {
						if (isEmpty(intersection(filters[key], video[key]))) {
							return false;
						}
					} else {
						if (isUndefined(video[key]) || !filters[key].includes(video[key])) {
							return false;
						}
					}
				}
				return true;
			});
		}
		return videos;
	}

	getPossibleSearchTerms(text) {
		return text.split(' ').map((obj) => {
			return obj.toLowerCase();
		});
	}
	textSearch(videos, text) {
		if (isEmpty(text)) {
			return videos;
		}
		const allPossibleTerms = this.getPossibleSearchTerms(text.trim());
		let counted = videos
			.map((video) => {
				let occuranceInValue = 0;
				for (var key in video) {
					if (isArray(video[key])) {
						for (let eachValueIndex in video[key]) {
							const eachValue = video[key][eachValueIndex].toLowerCase();
							for (let eachTermIndex in allPossibleTerms) {
								const eachTerm = allPossibleTerms[eachTermIndex];
								if (eachValue.includes(eachTerm)) {
									occuranceInValue++;
								}
							}
						}
					} else if (
						key === 'preview_image' ||
						key === 'contentful_video_id' ||
						key === 'vimeo_video_id'
					) {
						continue;
					} else {
						for (let eachTermIndex in allPossibleTerms) {
							const eachTerm = allPossibleTerms[eachTermIndex];
							if (
								!isNull(video[key]) &&
								video[key].toLowerCase().includes(eachTerm.toLowerCase())
							) {
								occuranceInValue++;
							}
						}
					}
				}
				return { data: video, count: occuranceInValue };
			})
			.filter((obj) => {
				return obj.count > 0;
			});
		if (allPossibleTerms.length > 0) {
			return counted
				.sort((a, b) => (a.count > b.count ? -1 : b.count > a.count ? 1 : 0))
				.map((obj) => {
					return obj.data;
				});
		} else {
			return videos;
		}
	}

	getVimeoVideo(videoId, callback, errorCallback) {
		axiosOriginal.get(
			getApiUrl('GET_VIMEO_VIDEO', { vimeoId: videoId }),
			{
				headers:
					{ 'Authorization': `Bearer ${process.env.REACT_APP_VIMEO_AUTH_TOKEN}` }
			})
			.then(response => {
				callback(response.data);
			})
			.catch(error => {
				errorCallback(error);
			})
	}
}
