import React from "react";
import logo from '../../assets/images/zenevate_logo.png';
import Url from "../../url";
import _ from "underscore";
import AppComponent from "../app_component";
import { createBrowserHistory } from 'history';
import {Col} from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Analytics from '../../models/Analytics';

export default class NavBar extends AppComponent {

    constructor(props) {
        super(props);
        _.bindAll(this, 'logout');
    }

    logout = () => {
        window.clearStorage();
        const history = createBrowserHistory();
        history.push(Url.LOGIN);
        window.loggedInUser = null;
        window.clearStorage();
        window.location.reload(true);
        Analytics.onLogout();
    };

    render() {
        const pathname=window.location.pathname;
        if (!window.loggedInUser) {
            return (<div />);
        }

        return (
            <Navbar className='navbar' expand="lg" fixed="top">
              <Col md={{span: 10, offset:1}} className="px-0">
                <div className='container-fluid px-md-0'>
                    <Navbar.Brand href={Url.CONTENT}>
                        <img className="zenevate_logo" src={logo}  alt="Zenevate"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="justify-content-end nav-bar-toggle-button"/>
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="w-100">
                          <Col  lg={{offset:2, span:10}} xl={{offset:5, span:7 }}className="d-lg-flex justify-content-between pt-2 pt-md-1">
                            <Nav.Link href="/content" className={pathname=="/content" ? "nav_link color-green" : "nav_link" }>Practice</Nav.Link>
                            <Nav.Link href="/journey" className={pathname=="/journey" ? "nav_link color-green" : "nav_link" }>My Journey</Nav.Link>
                            <Nav.Link href="/instructors" className='nav_link d-none'>Instructors</Nav.Link>
                            <NavDropdown title={ <span> Namaste, <span className="font-weight-100">{window.loggedInUser.firstName} </span></span>} 
                              className={pathname=="/account" ? "nav_username color-green" : "nav_username" } id='account'>
                                <NavDropdown.Item href="/account" className="py-2">My Account</NavDropdown.Item>
                                <NavDropdown.Item onClick={this.logout} href="#" className="pb-2">Logout</NavDropdown.Item>
                            </NavDropdown>
                           </Col> 
                        </Nav>
                    </Navbar.Collapse>
                </div>
                </Col>
            </Navbar>
        );
    }
}