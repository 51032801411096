import User from './User';
import StripeSubscription from './StripeSubscription';

class Analytics {
	static initialize() {
		var analytics = (window.analytics = window.analytics || []);
		if (!analytics.initialize)
			if (analytics.invoked)
				window.console &&
					console.error &&
					console.error('Segment snippet included twice.');
			else {
				analytics.invoked = !0;
				analytics.methods = [
					'trackSubmit',
					'trackClick',
					'trackLink',
					'trackForm',
					'pageview',
					'identify',
					'reset',
					'group',
					'track',
					'ready',
					'alias',
					'debug',
					'page',
					'once',
					'off',
					'on',
					'addSourceMiddleware',
					'addIntegrationMiddleware',
				];
				analytics.factory = function (e) {
					return function () {
						var t = Array.prototype.slice.call(arguments);
						t.unshift(e);
						analytics.push(t);
						return analytics;
					};
				};
				for (var e = 0; e < analytics.methods.length; e++) {
					var t = analytics.methods[e];
					analytics[t] = analytics.factory(t);
				}
				analytics.load = function (e, t) {
					var n = document.createElement('script');
					n.type = 'text/javascript';
					n.async = !0;
					n.src =
						'https://cdn.segment.com/analytics.js/v1/' +
						e +
						'/analytics.min.js';
					var a = document.getElementsByTagName('script')[0];
					a.parentNode.insertBefore(n, a);
					analytics._loadOptions = t;
				};
				analytics.SNIPPET_VERSION = '4.1.0';
				analytics.load(process.env.REACT_APP_SEGMENT_ANALYTICS_KEY);
				analytics.page();
			}
	}

	static async identifyUserLogin(id, name, email, subscription_details) {
		let userModel = new User();
		window.analytics.identify(id, {
			name: name,
			email: email,
			stripeSubscriptionStatus: subscription_details.stripe ?   subscription_details.stripe.status : "Never Subscribed" ,
			stripeChannelSubscribed:  subscription_details.stripe ?  subscription_details.stripe.instructor_contentful_id : "None"  ,
			iapSubscriptionStatus:  subscription_details.iap ?  subscription_details.iap.status :  "Never Subscribed" ,
			iapChannelSubscribed:  subscription_details.iap ?  subscription_details.iap.instructor_contentful_id : "None",
			channelSubscribed: userModel.getOverallSubscriptionStatus(subscription_details)
		});
	}

	static captureEvent(event) {
		window.analytics.track(event);
	}

	static capturedVideoDetailOpened(contentfulVideoId) {
		window.analytics.track('viewedVideoDetail', {
			contentfulVideoId: contentfulVideoId,
		});
	}

	static videoWatched(contentfulVideoId, overallDuration) {
		window.analytics.track('watchedVideo', {
			watchedSeconds: overallDuration,
			contentfulVideoId: contentfulVideoId,
		});
	}

	static videoStarted(contentfulVideoId) {
		window.analytics.track('videoOpened', {
			contentfulVideoId: contentfulVideoId,
		});
	}

	static onLogout() {
		window.analytics.reset();
	}

	static trackNavigation(url) {
		window.analytics.page(url);
	}
}

export default Analytics;
