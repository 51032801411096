import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { isEmpty } from "underscore";
import pay from "../../assets/images/pay.png";
import Stripe from "../stripe/Stripe";
import StripeSubscription from "../../models/StripeSubscription";
import CancelSubscription from "../stripe/CancelSubscription";
import DateUtil from "../../utils/dateUtil";
import User from "../../models/User";

export default class StripePaymentDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showUpdateCardPopup: false,
            stripe_details: props.subscription ? props.subscription.stripe : {},
            user: new User(),
            showCancelSubscriptionModal: false
        };
    }

    handleClose = (last_four) => {
        let stripe_details = this.state.stripe_details;
        if (!isEmpty(last_four)) {
            stripe_details.last_four = last_four;
        }
        this.setState({ showUpdateCardPopup: false, stripe_details });
    };

    handleShow = (e) => {
        e.preventDefault();
        this.setState({ showUpdateCardPopup: true });
    };

    renderBillingDate() {
        let classNameForText = StripeSubscription.activeStatus.includes(this.state.stripe_details.status) ? "details" : "inactive-subscription-text"
        if (this.state.stripe_details.cancel_on) {
            return (
                <p className={`${classNameForText} heading-5`}>
                    Your subscription will end on
                    <span className="font-weight-bold"> {DateUtil.epochToLocalDate(this.state.stripe_details.cancel_on)}</span>
                </p>
            );
        }
        return (
            <p className={`${classNameForText} heading-5`}>
                Next billing date
                <span className="font-weight-bold heading-5"> {DateUtil.epochToLocalDate(this.state.stripe_details.next_billing_date)}</span>
            </p>
        );
    }

    renderUpdateCardLink() {
        let stripe_details = this.props.subscription.stripe;
        if (stripe_details.cancel_on || stripe_details.status === StripeSubscription.status_cancelled) {
            return (<div />);
        }

        return (
            <React.Fragment>
                <p><a className="link cursor-pointer heading-5" onClick={this.handleShow}>Update payment details</a></p>
                <Stripe
                    show={this.state.showUpdateCardPopup}
                    handleClose={this.handleClose}
                    holderEmail={window.loggedInUser.email}
                />
            </React.Fragment>
        );
    }

    renderCard() {
        let stripe_details = this.props.subscription.stripe;
        let classNameForText = StripeSubscription.activeStatus.includes(stripe_details.status) ? "details" : "inactive-subscription-text"
        return (
            <Row>
                <Col lg="8" xs={12}>
                    <p className={`${classNameForText} pb-0 mt-1 heading-5`}>
                        <img src={pay} alt="card number" className="pr-md-4 pr-2" />
                        <span className="card_dots pr-1 heading-5">.... .... ....</span>
                        {stripe_details.last_four}
                    </p>
                </Col>
                <Col lg="4" className='pl-md-3 heading-5 d-none d-lg-block'>
                    {this.renderUpdateCardLink()}
                </Col>
            </Row>
        );
    }

    showStatus() {
        if (this.props.subscription.stripe) {

            let subscription_status = StripeSubscription.getStatusToDisplay(this.props.subscription.stripe);

            if (!subscription_status) {
                subscription_status = 'Canceled';
            }

            return (
                <Col sm={12} lg={7}>
                    <p className={"mb-0 mb-md-1 " + (subscription_status === 'Canceled' ? 'red-dot' : 'dot')} />
                    <span className={"pl-2 heading-4 active-profile " + (subscription_status === 'Canceled' ? 'red-color' : 'green-color')}>
                        {subscription_status}
                    </span>
                </Col>
            );
        }
    }

    handleCancelSubscriptionModalShow = (subscriptionId) => {
        this.setState({
            showCancelSubscriptionModal: true,
            cancelSubscriptionId: subscriptionId,
        });
    };

    handleCancelSubscriptionModalClose = (cancel_on) => {
        this.props.onCancelSubscription(cancel_on);
        this.setState({
            showCancelSubscriptionModal: false,
            cancelSubscriptionId: '',
        });
    };

    renderCancelSubscription() {
        let stripe_details = this.props.subscription.stripe;
        if (stripe_details.cancel_on ||
            this.props.subscription.stripe.status === StripeSubscription.status_cancelled) {
            return (<div />);
        }

        return (
            <Col>
                <p>
                    <a
                        className="link cursor-pointer heading-5"
                        onClick={() => this.handleCancelSubscriptionModalShow(stripe_details.subscription_id)}
                    >
                        Cancel subscription
                    </a>
                </p>
                <CancelSubscription
                    show={this.state.showCancelSubscriptionModal}
                    handleClose={this.handleCancelSubscriptionModalClose}
                    subscriptionId={stripe_details.subscription_id}
                    channelName={stripe_details.subscribed_to}
                />
            </Col>
        );
    }

    render() {

        let stripe_details = this.state.stripe_details;

        if (isEmpty(stripe_details)) {
            return <div />;
        }

        let classNameForText = StripeSubscription.activeStatus.includes(stripe_details.status) ? "details" : "inactive-subscription-text"

        return (
            <React.Fragment>
                {(() => {
                    let subscription = this.props.subscription;
                    if (subscription && (!isEmpty(subscription.iap))) {
                        return <hr />;
                    }
                })()}
                <Row className="mb-3 mt-0 mt-md-5">
                    {this.showStatus()}
                </Row>
                <React.Fragment>
                    <Row>
                        <Col sm={8} md={12}>
                            <Row>
                                <Col lg={8} sm={12} xs={12} >
                                    <p className={`${classNameForText} heading-5`}>Subscribed to
                                    <span className="font-weight-bold heading-5"> {stripe_details.subscribed_to}</span>
                                    </p>
                                </Col>
                                <Col className="d-none d-lg-block" lg={4}>
                                    <Row>
                                        {this.renderCancelSubscription()}
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className='pr-0'>
                                    <p className={`${classNameForText} pr-0 heading-5`}>Subscribed on
                                    <span className="font-weight-bold heading-5"> {DateUtil.epochToLocalDate(stripe_details.subscribed_on)}</span>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <p className={`${classNameForText} heading-5`}>Subscription cost
                                    <span className="font-weight-bold heading-5"> {StripeSubscription.formatCost(stripe_details.cost)} per month</span>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </React.Fragment>
                {this.renderCard()}
                <Row>
                    <Col sm={12}>
                        {this.renderBillingDate()}
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <p className={`${classNameForText} heading-5`}>Last payment date
                            <span className="font-weight-bold pl-1 heading-5">
                                {DateUtil.epochToLocalDate(stripe_details.last_payment_date)} paid {StripeSubscription.formatCost(stripe_details.amount_paid)}
                            </span>
                        </p>
                    </Col>
                </Row>
                <Row className="d-block d-lg-none">
                    {this.renderCancelSubscription()}
                </Row>
                <Row className="d-block d-lg-none">
                    <Col xs={12} className='pl-md-3 heading-5'>
                        {this.renderUpdateCardLink()}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}