import React from 'react';
import ReactDOM from 'react-dom';
import BrowserRouter from "react-router-dom/BrowserRouter";
import './assets/sass/App.scss';
import * as serviceWorker from './serviceWorker';
import NavBar from "./components/common/nav_bar";
import Footer from "./components/common/footer";
import Router from "./router";
import User from "./models/User";
import axios from "./axios/index"
import $ from 'jquery';
import { createBrowserHistory } from 'history';
import Url from "./url";
import Analytics from './models/Analytics';
import loadIntercom from "./utils/intercomLoader";

$(document).ready(() => {

    loadIntercom();
    const requestHandler = (config) => {
        let token = window.getJwtToken();
        if (token) {
            config.headers = {
                'User-Token': token
            };
        }
        $('#loading').show();
        return config;
    };

    const responseHandler = (response) => {
        $('#loading').hide();
        return response;
    };

    const errorHandler = (error) => {
        if (error.response.status === 401) {
            window.clearStorage();
            const history = createBrowserHistory();
            history.push(Url.LOGIN);
            window.loggedInUser = null;
            window.location.reload(true);
        }
        $('#loading').hide();
        return Promise.reject(error);
    };

    axios.interceptors.request.use(request => requestHandler(request));

    axios.interceptors.response.use(
        response => responseHandler(response),
        error => errorHandler(error)
    );

    Analytics.initialize();
    window.addEventListener('appinstalled', e => {
          Analytics.captureEvent('appInstalled');
    });

    window.addEventListener('beforeinstallprompt', e => {
        Analytics.captureEvent('appInstallationPromptDisplayed');
  });

    window.getJwtToken = () => window.localStorage.getItem('token');

    window.storeToken = (token) => {
        window.localStorage.setItem('token', token);
    };

    window.clearStorage = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
    };

    window.setRefreshToken = (refreshToken) => window.localStorage.setItem('refreshToken', refreshToken);

    window.getRefreshToken = () => window.localStorage.getItem('refreshToken');

    window.setTokenExpiry = (tokenExpiresAt) => window.localStorage.setItem('tokenExpiresAt', tokenExpiresAt);

    window.getTokenExpiry = () => window.localStorage.getItem('tokenExpiresAt');

    window.renderDashboard = () => {
        ReactDOM.render(
            (
                <BrowserRouter>
                    <div>
                        <Router />
                    </div>
                </BrowserRouter>
            ), document.getElementById('content')
        );
    };

    window.setIntercom = (loggedInUser = null) => {
        if (!!window.Intercom) {
            let intercomData = {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                custom_launcher_selector: '#intercom_chat'
            }
            if (loggedInUser) {
                intercomData.name = loggedInUser.firstName + ' ' + loggedInUser.lastName;
                intercomData.email = loggedInUser.email;
                intercomData.user_hash = loggedInUser.intercomHash;
            }
            window.Intercom('boot', intercomData);
        }
    }

    window.renderNavBar = () => {
        ReactDOM.render(
            (
                <NavBar />
            ), document.getElementById('navbar')
        );
    };
    window.renderFooter = () => {
        ReactDOM.render(
            (
                <Footer />
            ), document.getElementById('footer')
        );
    };

    window.userInit = (user) => {
        window.loggedInUser = user;
        window.setIntercom(window.loggedInUser);
    };

    window.getMe = (callback) => {
        let user = new User();

        // if ( Date.now() > Date.parse(window.getTokenExpiry())) {
        //     user.refreshToken({'refresh_token': window.getRefreshToken()}).then(function (response) {
        //         window.setTokenExpiry(response.tokenExpiresAt);
        //         window.setRefreshToken(response.refreshToken);
        //         window.storeToken(response.idToken);
        //         window.renderNavBar();
        //         window.renderDashboard();
        //     }).catch(() => {
        //         window.clearStorage();
        //         window.location.reload(true)
        //     });
        // }

        user.getMe().then((response) => {
            window.userInit(response.data);
            if (callback) {
                callback();
            }
            window.renderNavBar();
            window.renderDashboard();
            window.renderFooter();
        }).catch(() => {
            window.clearStorage();
            window.location.reload(true)
        });

    };

    if (window.getJwtToken()) {
        window.getMe();
    }

    window.renderDashboard();
    window.renderFooter();
    window.setIntercom();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register();
});
