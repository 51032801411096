import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import VideoStats from './video_stats';
import _, { isEmpty } from 'underscore';
import ViewingHistory from '../../models/ViewingHistory';
import VideoHistory from './video_history';
import Url from '../../url';
import AppComponent from '../app_component';
import ErrorBanner from '../common/ErrorBanner';
import User from '../../models/User';

export default class MyJourneyPage extends AppComponent {

    constructor(props) {
        super(props);
        this.state = {
            viewing_history: {},
            no_history: false,
            hasNoSubscribedHistory: false,
            isSubscriptionActive: true,
        };
        this.userModel = new User();
        _.bindAll(this, 'fetchViewingHistory', 'navigate', 'fetchSubscriptionsStatus')
    }

    componentDidMount() {
        window.getMe(()=>{
            this.fetchViewingHistory();
            this.fetchSubscriptionsStatus();
        }) 
    }

    fetchSubscriptionsStatus = () => {
        const subscription_details = JSON.parse(window.sessionStorage.getItem('subscription_details'));
		if (subscription_details) {
            this.setState({
                hasNoSubscribedHistory: this.userModel.hasNoSubscribedHistory(subscription_details),
                isSubscriptionActive: this.userModel.isSubscriptionActive(subscription_details)
			})
        } else {
            this.userModel.getSubscriptions((response) => {
                this.setState({
                    hasNoSubscribedHistory: this.userModel.hasNoSubscribedHistory(response),
                    isSubscriptionActive: this.userModel.isSubscriptionActive(response)
                })
            });
        }
	}  


    fetchViewingHistory = () => {
        let viewingHistory = new ViewingHistory();
        viewingHistory.getViewingHistory()
            .then((response) => {
                let no_history = false;
                if (isEmpty(response.data)) {
                    no_history = true;
                }
                this.setState({ viewing_history: response.data, no_history})
            })
    }

    navigate(contentful_video_id) {
        const url = `${Url.VIEW_VIDEO_DETAILS}/${contentful_video_id}`;
        this.navigateTo(url);
    }

    render() {

        if (this.state.no_history) {
            return (
                <Container className='pt-5'>
                    <Row className="d-flex justify-content-center">
                        <ErrorBanner visible={!this.state.isSubscriptionActive} hasNoSubscribedHistory={this.state.hasNoSubscribedHistory}
                        />
                    </Row>
                    <h1 className='journey-heading mt-4 text-center'>
                        When you watch a video, it will appear here for easy future access.
                    </h1>
                </Container>
            );
        }

        if (isEmpty(this.state.viewing_history)) {
            return (<div />);
        }

        return (
            <Container className="pt-5">
                <Row className="d-flex justify-content-center">
                <ErrorBanner visible={!this.state.isSubscriptionActive} hasNoSubscribedHistory={this.state.hasNoSubscribedHistory} />
                </Row>
                <Row>
                    <Col className='journey-heading mt-4' md={{span: 10, offset: 1}}>
                        My Journey
                    </Col>
                </Row>
                <VideoStats  viewing_history={this.state.viewing_history} />
                <VideoHistory viewing_history={this.state.viewing_history} onClick={this.navigate}/>
            </Container>
        );
    }
}