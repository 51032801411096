import BaseModel from "./BaseModel";
import {getApiUrl} from "../utils/apiUrls";
import axios from '../axios'
import DateUtil from "../utils/dateUtil";
import {isEmpty} from "underscore";

export default class StripeSubscription extends BaseModel {

    static status_active = 'active';

    static status_trialing = 'trialing';

    static status_cancelled = 'canceled';

    static status_past_due = 'past_due';

    static activeStatus = [this.status_active, this.status_trialing, this.status_past_due];

    async addNewCard({userId, cardToken}) {
        return await axios.put(getApiUrl("ADD_CARD", {id: userId}),
            {},
            {
                params: { card_token: cardToken.id, set_as_default: true},
            })
    }

    static async cancelSubscription(subscriptionToken, userId) {
        return await axios.delete(
            getApiUrl("CANCEL_SUBSCRIPTION", {id: userId}),{
                params: { subscription_token: subscriptionToken},
            }
        );
    }

    static formatCost(cost) {
        let dollars = cost / 100;
        return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
    }

    static getStatusToDisplay(stripe) {
        if (isEmpty(stripe)) {
            return null;
        }

        if (stripe.status === this.status_active  && !stripe.cancel_on) {
            return 'Active';
        }

        if (stripe.cancel_on) {
            return  'Active, Cancels on ' + DateUtil.epochToLocalDate(stripe.cancel_on);
        }

        if (stripe.status === this.status_trialing) {
            return 'Trialing, Ends on ' + DateUtil.epochToLocalDate(stripe.trial_end);
        }

        if (stripe.status === this.status_past_due) {
            return 'Past Due'
        }
        return null;
    }
}