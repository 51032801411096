import AppComponent from '../app_component';
import React from 'react';
import { Col, Dropdown } from 'react-bootstrap';

class FilterDropDown extends AppComponent {
	onCheckboxClick(e, value) {
		this.props.onCheckboxClick(this.props.category, value);
	}

	isCheckboxSelected(option) {
		return (
			this.props.selectedOptions.filter(
				(selectedOption) => option === selectedOption
			).length > 0
		);
	}

	renderItems() {
		return this.props.options.map((option) => {
			return (
				<Dropdown.Item
					onClick={(e) => this.onCheckboxClick(e, option)}
					key={option}
				>
					<div className="ml-4 custom-control custom-checkbox">
						<input
							type="checkbox"
							checked={this.isCheckboxSelected(option)}
							className="custom-control-input"
							id={`checkbox-${option}`}
							onChange={(e) => this.onCheckboxClick(e, option)}
						/>
						<label
							className="custom-control-label"
							htmlFor={`checkbox-${option}`}
						>
							{option}
						</label>
					</div>
				</Dropdown.Item>
			);
		});
	}

	render() {
		return (
			<Col md={4} className="col-lg mt-3">
				<Dropdown drop={this.props.subMenu ? 'right' : 'down'}>
					<Dropdown.Toggle
						bsPrefix={`dropdown-toggle ${
							this.props.subMenu ? 'sub-menu-toggle' : 'filterDropDown heading-5'
						}`}
						variant="light"
						className="heading-5"
					>
						{this.props.title}
					</Dropdown.Toggle>
					<Dropdown.Menu>{this.renderItems()}</Dropdown.Menu>
				</Dropdown>
			</Col>
		);
	}
}

export default FilterDropDown;
