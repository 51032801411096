import React from 'react';
import { Image, Row, Col, Breadcrumb, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isEmpty } from 'underscore';
import Url from '../../url';
import AppComponent from '../app_component';
import InstructorModel from '../../models/Instructor';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/instagram.svg';
import twitter from '../../assets/images/twitter.svg';
import website from '../../assets/images/website.svg';
import spotify from '../../assets/images/spotify.svg';
import lotusImage from '../../assets/images/lotus_yoga.svg';
import pin from '../../assets/images/pin.svg';
import ErrorBanner from '../common/ErrorBanner';
import User from '../../models/User';

export default class InstructorDetailsPage extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            instructor_id: window.location.pathname.split('/')[2],
            instructor_model: null,
            errors: null,
            videoTitle: props.history.location.state ? props.history.location.state.videoTitle : null,
            videoId: props.history.location.state ? props.history.location.state.videoId : null,
            hasNoSubscribedHistory: false,
            isSubscriptionActive: true
        }
    }

    componentDidMount() {
        window.getMe(() => {
            this.fetchInstructorDetails()
            this.fetchSubscriptionDetails()
        });
    }

    fetchSubscriptionDetails() {
        const user = new User(window.loggedInUser);
        const subscription_details = JSON.parse(window.sessionStorage.getItem('subscription_details'));
		if (subscription_details) {
            this.setState({
                hasNoSubscribedHistory: user.hasNoSubscribedHistory(subscription_details),
                isSubscriptionActive: user.isSubscriptionActive(subscription_details)
            });
        } else {
            user.getSubscriptions((response) => {
                this.setState({
                    hasNoSubscribedHistory: user.hasNoSubscribedHistory(response),
                    isSubscriptionActive: user.isSubscriptionActive(response)
                });
            });
        }
    }

    fetchInstructorDetails() {
        const instructor = new InstructorModel();
        instructor.getInstructorDeatils(
            this.state.instructor_id,
            response => this.onSuccess(response),
            error => this.onFail(error)
        );
    }

    onSuccess(response) {
        this.setState({ instructor_model: response });
    }

    onFail(errors) {
        this.setState({ errors });
    }

    renderSocialMediaLinks() {
        const links = {
            facebook: facebook,
            twitter: twitter,
            instagram: instagram,
            website: website,
            spotify: spotify,
        };
        const totalSocialMediaLinks = this.state.instructor_model.social_media;
        const socialMediaLinkComponent = [];

        Object.keys(totalSocialMediaLinks).forEach(function (key) {
            if (totalSocialMediaLinks[key]) {
                socialMediaLinkComponent.push(
                    <a
                        href={totalSocialMediaLinks[key]}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-3 cursor-pointer social-link-position"
                        key={key}
                    >
                        <Image
                            src={links[key]}
                            fluid
                            roundedCircle
                            className="social-link-image"
                        />
                    </a>
                );
            }
        });
        return socialMediaLinkComponent;
    }

    renderYogaStyles() {
        const allStyles = this.state.instructor_model.yoga_types;
        const yogaStyleComponent = [];
        allStyles.forEach(function (eachStyle, index) {
            if(eachStyle){
                yogaStyleComponent.push(
                        <Col className="mt-3" md={{ span: 4 }} xs={{ span: 12 }}>
                            <Row>
                                <Image src={lotusImage} className="style-image mr-2" />
                                <span className="heading-4 yogaStyle-alignment">
                                    {eachStyle}
                                </span>
                            </Row>
                        </Col>
                    );
            }
        }
        );
        return yogaStyleComponent;
    }

    renderCertificates() {
        const certificates = this.state.instructor_model.certifications;
        const certificateComponent = [];
        certificates.forEach(function (eachCerti) {
            certificateComponent.push(
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 300 }}
                    key={`certi-overlay-${certificates.indexOf(eachCerti)}`}
                    overlay={
                        <Tooltip id={`certi-${certificates.indexOf(eachCerti)}`}>
                            {eachCerti['name']}
                        </Tooltip>
                    }
                >
                    <Image src={eachCerti['logo']} className="certification-image mr-3" />
                </OverlayTrigger>
            );
        });
        return certificateComponent;
    }

    render() {
        if (isEmpty(this.state.instructor_model)) {
            return (<div />);
        }
        return (
            <div className="instructor-details-page">
                <div className="top-banner d-none d-md-block">
                    <Image src={this.state.instructor_model.banner_image} className="w-100" />
                    <div className="banner-text">
                        <div className="banner-heading">
                            {this.state.instructor_model.name}
                        </div>
                        <div className="banner-sub-heading mt-2">
                            {this.state.instructor_model.landing_quote}
                        </div>
                    </div>
                </div>
                <Col xs={{ span: 10, offset: 1 }} className="my-5">
                    <Row className="d-flex justify-content-center mb-3 mobile-top-margin">
                        <ErrorBanner visible={!this.state.isSubscriptionActive} hasNoSubscribedHistory={this.state.hasNoSubscribedHistory} />
                    </Row>
                    <Row className="instructor-detail-box">
                        <Col xs={{ span: 12 }} className="d-block d-md-none mt-3">
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => this.navigateTo(Url.CONTENT)}>
                                    Practice
                                </Breadcrumb.Item>
                                {(() => {
                                    if (this.state.videoTitle && this.state.videoId) {
                                        return (
                                            <Breadcrumb.Item onClick={() => this.navigateTo(`${Url.VIEW_VIDEO_DETAILS}/${this.state.videoId}`)}>
                                                {this.state.videoTitle}
                                            </Breadcrumb.Item>
                                        );
                                    }
                                })()}
                                <Breadcrumb.Item className="breadcrumb-active-color" active>
                                    {this.state.instructor_model.name}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col md={{ span: 10, offset: 1 }} className="mb-5 mt-md-5 mt-xs-n5">
                            <Row>
                                <Col md={{ span: 4 }} xs={{ span: 12 }}>
                                    <Image src={this.state.instructor_model.profile_image} className="profile-image" />
                                </Col>
                                <Col md={{ span: 8 }} xs={{ span: 12 }} className="pl-md-4">
                                    <Row className="d-none d-md-block">
                                        <Col md={{ span: 12 }}>
                                            <Breadcrumb>
                                                <Breadcrumb.Item onClick={() => this.navigateTo(Url.CONTENT)}>
                                                    Practice
                                                </Breadcrumb.Item>
                                                {(() => {
                                                    if (this.state.videoTitle && this.state.videoId) {
                                                        return (
                                                            <Breadcrumb.Item onClick={() => this.navigateTo(`${Url.VIEW_VIDEO_DETAILS}/${this.state.videoId}`)}>
                                                                {this.state.videoTitle}
                                                            </Breadcrumb.Item>
                                                        );
                                                    }
                                                })()}
                                                <Breadcrumb.Item className="breadcrumb-active-color" active>
                                                    {this.state.instructor_model.name}
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </Col>
                                    </Row>
                                    <Row className="heading-3">
                                        <Col xs={{ span: 12 }} className="mt-4 mt-md-0">
                                            Styles of yoga
                                        </Col>
                                    </Row>
                                    <Row xs={{ span: 12 }} className="pl-3">
                                        {this.renderYogaStyles()}
                                    </Row>
                                    <Row className="heading-3 mt-4">
                                        <Col xs={{ span: 12 }}>
                                            Certifications
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={{ span: 12 }}>
                                            {this.renderCertificates()}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-3 text-center text-md-left">
                                <Col md={{ span: 12 }} className="heading-2 d-none d-md-block mt-3">
                                    About {this.state.instructor_model.name}
                                    <span className="ml-4 mt-1 mb-1">{this.renderSocialMediaLinks()}</span>
                                </Col>
                                <Col xs={{ span: 12 }} className="heading-2 d-block d-md-none mt-2">
                                    About {this.state.instructor_model.name}
                                </Col>
                            </Row>
                            <Row className="text-center text-md-left">
                                <Col md={{ span: 12 }}>
                                    <Image src={pin} className="mr-2 pin-style" />
                                    <span className="heading-4">
                                        {this.state.instructor_model.location.description}
                                    </span>
                                </Col>
                                <Col xs={{ span: 12 }} className="heading-2 ml-md-4 my-2 inline-block d-block d-md-none">
                                    {this.renderSocialMediaLinks()}
                                </Col>
                            </Row>
                            <Row className="text-left">
                                <Col md={{ span: 12 }} className="heading-4 mt-3">
                                    <div className="text-pre-wrap" dangerouslySetInnerHTML={{ __html: this.state.instructor_model.bio }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        );
    }
}
