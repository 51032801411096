import React from 'react';
import _ from 'underscore';
import $ from 'jquery';
import messages from "../assets/json/messages.json";
import Analytics from '../models/Analytics';

export default class AppComponent extends React.Component {
    constructor(props) {
        super(props);
        _.bindAll(this, 'updateText', 'handleChange', 'renderError', 'navigateTo', 'navigateBack', 'replaceTo');
    }

    componentDidMount() {
        $(window).scrollTop(0);
    }

    updateText(event) {
        const attributeName = event.target.name;
        const attributeValue = event.target.value;

        this.state.model.set(attributeName, attributeValue);
        this.setState({ model: this.state.model });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    navigateTo(url, state = {}) {
        Analytics.trackNavigation(url);
        this.props.history.push(url, state);
    }

    navigateBack() {
        if (this.props.history.length > 2) {
            this.props.history.goBack();
            Analytics.trackNavigation(this.props.history.location);
            return true;
        } else {
            return false;
        }
    }

    replaceTo(url, state = {}) {
        Analytics.trackNavigation(url);
        this.props.history.replace(url, state);
    }

    renderError(key, className = 'text-danger') {
        const containsError = this.state.errors && this.state.errors[key] !== undefined;
        if (containsError) {
            const errorMessage = this.state.errors[key];
            return <span className={className}>{messages[errorMessage] ? messages[errorMessage] : errorMessage}</span>;
        }
    }
}
