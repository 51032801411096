import React from "react";
import { Route, Redirect } from "react-router-dom/es";
import { Switch } from "react-router";
import Url from "./url";
import LoginPage from "./components/login/login_page";
import VideoDetailsPage from "./components/video/VideoDetailsPage";
import SearchContent from "./components/searchcontent/SearchContent";
import ResetPassword from "./components/login/reset_password";
import AccountPage from "./components/account/account_page";
import MyJourneyPage from "./components/my_journey/my_journey_page";
import VideoPlayer from "./components/video/VideoPlayer";
import UpdatePasswordPage from "./components/account/update_password_page";
import InstructorDetailsPage from "./components/instructor/InstructorDetailsPage";

const Router = () => (
    <div>
        <Switch>
            <PrivateRoute exact path={Url.ACCOUNT} component={AccountPage} />
            <PrivateRoute exact path={Url.PRACTICE} component={SearchContent} />
            <PrivateRoute exact path={`${Url.VIEW_VIDEO_DETAILS}/:contentful_video_id`} component={VideoDetailsPage} />
            <PrivateRoute exact path={Url.CONTENT} component={SearchContent} />
            <PrivateRoute exact path={Url.MY_JOURNEY} component={MyJourneyPage} />
            <PrivateRoute exact path={Url.HOME_PAGE} component={SearchContent} />
            <PrivateRoute exact path={`${Url.VIDEO_PLAYER}/:contentful_video_id/:vimeo_id`} component={VideoPlayer} />
            <PrivateRoute exact path={Url.UPDATE_PASSWORD} component={UpdatePasswordPage} />
            <PrivateRoute exact path={`${Url.INSTRUCTOR_DETAIL}/:instructor_id`} component={InstructorDetailsPage} />
            <Route exact path={Url.RESET_PASSWORD} component={ResetPassword} />
            <Route exact path={Url.LOGIN} component={LoginPage} />
        </Switch>
    </div>
);

const defaultRedirection = (props) => {
    return (
        <Redirect to={{
            pathname: Url.LOGIN,
            state: { from: props.location }
        }}
        />
    );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            (!window.getJwtToken() || window.getJwtToken() === null) ? defaultRedirection(props) : (
                <div>
                    {window.renderNavBar()}
                    <Component {...props} />
                </div>
            )
        )}
    />
);

export default Router;
